import React from 'react'

const CustomProgessBar = ({ titleTop, color, done, total }) => {
  const percentage = (done / total) * 100;

  return (
    <div style={{ width: "30%" }}>
      <div style={{ fontSize: 12 }}>{titleTop}</div>
      <div className="progress" style={{ height: "7px" }}>
        <div className={`progress-bar ${color}`} role="progressbar" style={{ width: `${percentage}%`, height: "7px" }} aria-valuenow={percentage} aria-valuemin="0" aria-valuemax="100"></div>
      </div>
    </div>
  )
}

export default CustomProgessBar