import React, { useEffect, useState } from 'react'
import TableArea from '../../components/TableArea';
import { Button, Checkbox, Menu, message, Progress, Table, Upload } from 'antd';
import { Form, Input, Select, DatePicker, Row, Col, Modal } from 'antd';
import { KYCTableColumns, sampleClientsData } from '../../utils/clientsUtils';
import StepperComponent from '../../components/StepperComponent';
import { createStyles, useTheme } from 'antd-style';
import { Link, useParams } from 'react-router-dom';
import Dragger from 'antd/es/upload/Dragger';
import currenciesList from '../../utils/currenciesList';
import axiosInstance from '../../apiIntecepter/axiosInstance';

const { Option } = Select;

const useStyle = createStyles(({ token }) => ({
  'my-modal-body': {
    background: token.colorWhite,
    padding: token.paddingSM,
  },
  'my-modal-mask': {
    boxShadow: `inset 0 0 15px #fff`,
  },
  'my-modal-header': {
    borderBottom: `1px dotted ${token.colorPrimary}`,
  },
  'my-modal-footer': {
    color: token.colorPrimary,
  },
  'my-modal-content': {
    border: '1px solid #333',
  },
}));

const Shareholders = () => {
  const { clientId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);

  const [isOwnerEditing, setIsOwnerEditing] = useState(false);
  const [currentOwner, setCurrentOwner] = useState(null);
  const { styles } = useStyle();
  const token = useTheme();
  const [shareholdersData, setShareholdersData] = useState([])
  const [ownersData, setOwnersData] = useState([])
  const [isModalOpen, setIsModalOpen] = useState([false, false]);
  const [isOwnerModalOpen, setIsOwnerModalOpen] = useState([false, false]);
  const { confirm } = Modal;
  const [formData, setFormData] = useState({
    id:'',
    name: '',
    email: '',
    phone: '',
    shareType: '',
    shares: '',
    amount: '',
    currency: ''
  });


  const [formOwnerData, setFormOwnerData] = useState({
    id:'',
    ownerName: '',
    email: '',
    nationality: '',
    address: '',
    beneficialOwnership: ''
  });

  const toggleModal = (index, isOpen, client = null) => {
    const newModalState = [...isModalOpen];
    newModalState[index] = isOpen;
    setIsModalOpen(newModalState);
    setIsEditing(!!client);
    setCurrentClient(client);
  };
  const toggleOwnerModal = (index, isOpen, client = null) => {
    const newModalState = [...isOwnerModalOpen];
    newModalState[index] = isOpen;
    setIsOwnerModalOpen(newModalState);
    setIsOwnerEditing(!!client);
    setCurrentOwner(client);
  };

  const classNames = {
    body: styles['my-modal-body'],
    mask: styles['my-modal-mask'],
    header: styles['my-modal-header'],
    footer: styles['my-modal-footer'],
    content: styles['my-modal-content'],
  };

  const modalStyles = {
    header: {
      borderLeft: `5px solid ${token.colorPrimary}`,
      borderRadius: 0,
      paddingInlineStart: 5,
    },
    body: {
      borderRadius: 5,
    },
    mask: {},
    footer: {
      // borderTop: '1px solid #333',
    },
    content: {
      boxShadow: '0 0 30px #999',
    },
  };




  const showDeleteConfirm = (isShareholder, record) => {
    console.log("adasdasdas",record)
    console.log("ownersData",ownersData)
    confirm({
      title: 'Remove User',
      content: `Are you sure you want to delete ${isShareholder ? "shareholder" : "beneficialowners"}?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        if (isShareholder) {
          axiosInstance.delete(`/Client/shareholders/${record?.id}`).then((response) => {
            setShareholdersData(shareholdersData?.filter((shareholder) => shareholder?.id !== record?.id))
           
          }).catch((error) => { console.log("err", error) })
        }
        else {
          axiosInstance.delete(`/Client/beneficialowners/${record?.id}`).then((response) => {
            setOwnersData(ownersData?.filter((owner) => owner?.id !== record?.id))
          }).catch((error) => { console.log("err", error) })
        }
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleEditClick = (record, isOwner) => {
    if (isOwner) {
      setFormOwnerData({
        ownerName: record.ownerName,
        email: record.email,
        nationality: record.nationality,
        address: record.address,
        beneficialOwnership: record.beneficialOwnership
      })
      toggleOwnerModal(0, true,record);
    }
    else {
      setFormData({
        id:record.id,
        name: record.name,
        email: record.email,
        phone: record.phone,
        shareType: record.shareType,
        shares: record.shares,
        amount: record.amount,
        currency: record.currency,
      });
      toggleModal(0, true,record);
    }

  };

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleExpandClick = (recordId) => {
    setExpandedRowKeys((prevKeys) => {
      if (prevKeys.includes(recordId)) {
        // If the row is already expanded, remove it from the keys (collapse)
        return prevKeys.filter(key => key !== recordId);
      } else {
        // If the row is not expanded, collapse all others and expand this one
        return [recordId];
      }
    });
  };

  const shareholderTableColumns = [
    {
      title: 'S No',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <div>
            <span>
              <span to={`/new-client/${record?.id}`}>
                {record?.name}
              </span>
            </span>
          </div>
        )
      }
    },
    {
      title: 'Share Type',
      dataIndex: 'shareType',
      key: 'shareType',
    },
    {
      title: 'Shares',
      dataIndex: 'shares',
      key: 'shares',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
      key: 'amount',
    },
    {
      title: 'Currency',
      dataIndex: 'currency',
      key: 'currency',
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <span>
          <i className='fa fa-edit text-primary mx-3' onClick={() => handleEditClick(record, false)} />
          <i className='fa fa-trash text-danger' onClick={() => { showDeleteConfirm(true, record) }} />
        </span>
      ),
    },
  ];
  const unlimitedBenifialTableColumns = [
    {
      title: 'S No',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'ownerName',
      key: 'ownerName',
      render: (text, record) => {
        console.log("record", record)
        return (
          <div>
            <span>
              <span to={`/new-client/${record.id}`}>
                {record.ownerName}
              </span>
            </span>
          </div>
        )
      }
    },

    {
      title: 'Email',
      dataIndex: 'email',
      key: 'email',
    },
    {
      title: 'Nationality',
      dataIndex: 'nationality',
      key: 'nationality',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Percentage',
      dataIndex: 'beneficialOwnership',
      key: 'beneficialOwnership',
      render: (text) => <Progress type='circle' percent={text} width={40} />,
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <span>
          <i className='fa fa-edit text-primary mx-3' onClick={() => {
            handleEditClick(record, true)
          }} />
          <i className='fa fa-trash text-danger' onClick={() => { showDeleteConfirm(false, record) }} />
        </span>
      ),
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };


  const handleOwnerChange = (e) => {
    const { name, value } = e.target;
    setFormOwnerData({
      ...formOwnerData,
      [name]: value
    });
  };
  const handleSelectChange = (value, option) => {
    const { name } = option.props;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const expandedRowRender = (record) => {
    const { Dragger } = Upload;
    const props = {
      name: 'file',
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e77',
      onChange(info) {
        const { status } = info.file;
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      onDrop(e) {
        console.log('Dropped files:', e.dataTransfer.files);
      },
    };

    return (
      <tr>
        <td colSpan="8">
          <Dragger {...props} style={{ width: 600 }}>
            <p className="ant-upload-drag-icon">
              <i className='fa fa-plus' />
            </p>
            <p className="ant-upload-text">Upload Media</p>
            {/* <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              sensitive information.
            </p> */}
          </Dragger>
        </td>
      </tr>
    );
  };

  const sampleShareholdersData = [
    {
      sNo: 1,
      name: 'John Doe',
      email: 'john.doe@example.com',
      phone: '123-456-7890',
      shareType: 'Ordinary',
      shares: 1000,
      amount: 5000,
      currency: 'USD',
    },
    {
      sNo: 2,
      name: 'Jane Smith',
      email: 'jane.smith@example.com',
      phone: '098-765-4321',
      shareType: 'Preferred',
      shares: 2000,
      amount: 10000,
      currency: 'USD',
    },
  ];
  const sampleUnlimitedBenificialData = [
    {
      sNo: 1,
      name: 'Jane Smith',
      email: 'john.doe@example.com',
      nationality: 'American',
      address: '123 Main St, Anytown, USA',
      percentage: 10,
    },
    {
      sNo: 2,
      name: 'John Doe',
      email: 'jane.smith@example.com',
      nationality: 'Canadian',
      address: '456 Maple Rd, Othertown, Canada',
      percentage: 20,
    },
  ];


  useEffect(() => {
    const fetchShareholdersData = async () => {
      try {
        const contactsResponse = await axiosInstance.get(`/Client/${clientId}/shareholders`);
        setShareholdersData(contactsResponse.data);
      } catch (error) {
        console.error("Error fetching shareholders data:", error);
      }
    };
  
    fetchShareholdersData();
  }, [clientId]);
  
  useEffect(() => {
    const fetchBeneficialOwnersData = async () => {
      try {
        const beneficialownersResponse = await axiosInstance.get(`/Client/${clientId}/beneficialowners`);
        setOwnersData(beneficialownersResponse.data);
      } catch (error) {
        console.error("Error fetching beneficial owners data:", error);
      }
    };
  
    fetchBeneficialOwnersData();
  }, [clientId]);



  const handleOk = () => {
    console.log(formData)
    axiosInstance.post(`/Client/${clientId}/shareholders`, formData).then((response) => {
      console.log("added", response?.data);
      setShareholdersData([...shareholdersData, response?.data])
      toggleModal(0, false)
    }).catch((error) => { console.log("err", error) })
  };
  const handleEdit = () => {
    console.log("currentClient.id ::: ",currentClient)
    axiosInstance.put(`/Client/shareholders/${currentClient?.id}`, formData).then((response) => {

      console.log(shareholdersData , "shareholdersData")
      console.log(response?.data , "response?.data")
      setShareholdersData(shareholdersData?.map(note => 
        note?.id === currentClient.id ? formData : note
      ));
    
      toggleModal(0, false)
    }).catch((error) => { console.log("err", error) })
  };
  const handleOwnerOk = () => {
    let body = {
      "ownerName": formOwnerData?.ownerName,
      "email": formOwnerData?.email,
      "phone": "",
      "idNo": "",
      "nationality": formOwnerData?.nationality,
      "address": formOwnerData?.address,
      "beneficialOwnership": Number(formOwnerData?.beneficialOwnership),
      "clientId": 0
    }
    console.log(body)
    axiosInstance.post(`/Client/${clientId}/beneficialowners`, body).then((response) => {
      console.log("added", response?.data);
      setOwnersData([...ownersData, response?.data])
      toggleOwnerModal(0, false)
    }).catch((error) => { console.log("err", error) })
  };
  const handleOwnerEdit = () => {
    let body = {
      "ownerName": formOwnerData?.ownerName,
      "email": formOwnerData?.email,
      "phone": "",
      "idNo": "",
      "nationality": formOwnerData?.nationality,
      "address": formOwnerData?.address,
      "beneficialOwnership": Number(formOwnerData?.beneficialOwnership),
      "clientId": clientId
    }
    axiosInstance.put(`/Client/beneficialowners/${currentOwner?.id}`, body).then((response) => {

      setOwnersData(ownersData?.map(note => 
        note?.id === currentOwner?.id ? body : note
      ));
      toggleOwnerModal(0, false)
    }).catch((error) => { console.log("err", error) })
  };
  return (
    <>
      <div style={{ backgroundColor: "#eeeeee", padding: 30, position: "relative", bottom: 10 }}>
        <div className='container' style={{ background: "white", borderRadius: 20, padding: 40, }} >
          <div className='py-0' style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: 20 }}>
            <div style={{ height: 30, width: 30, borderRadius: 100, background: "orange", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <svg id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" className="styles_headerImg__1ihe_"><linearGradient id="CHART_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stopColor="#ffc8d1"></stop><stop offset="1" stopColor="#fff6f7"></stop></linearGradient><linearGradient id="CHART_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="407" y2="105"><stop offset="0" stopColor="#ffa68d"></stop><stop offset="1" stopColor="#fd3a84"></stop></linearGradient><g><g><circle cx="256" cy="256" fill="url(#CHART_1_)" r="256"></circle></g><g><g><path d="m368.704 155.483-106.424 106.581c-1.7 1.703-3.973 2.606-6.28 2.606-1.145 0-2.307-.217-3.4-.678-3.313-1.373-5.482-4.622-5.482-8.218v-150.774c-79.115 4.644-142.118 70.39-142.118 150.775 0 83.387 67.745 151.225 151 151.225s151-67.838 151-151.225c0-38.497-14.56-73.568-38.296-100.292zm-103.822-50.483v129.3l91.261-91.397c-24.604-21.919-56.325-35.853-91.261-37.903z" fill="url(#CHART_2_)"></path></g></g></g></svg>
            </div>
            <h6 className='mt-2 mx-2 fw-bold'>Shareholders</h6>
          </div>

          <Table
            // dataSource={sampleClientsData}
            dataSource={shareholdersData ? shareholdersData : []}
            columns={shareholderTableColumns}
            scroll={{ x: 'max-content' }}
            expandedRowRender={expandedRowRender}
            expandedRowKeys={expandedRowKeys}
            rowKey="sNo" // Ensure your data has a unique key
            onExpand={(expanded, record) => {
              handleExpandClick(record.sNo); // This will toggle the expanded state
            }}
          />

          <div className='mt-2 my-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
            <div className='d-flex'>
            </div>
            <div className='d-flex justify-content-between align-items-center gap-2 mx-3' onClick={() => {
              setFormData({
                id:'',
                name: '',
                email: '',
                phone: '',
                shareType: '',
                shares: '',
                amount: '',
                currency: ''
              });
              toggleModal(0, true);
            }}>
              <i className='fa fa-plus text-black mb-2 custom_add_icon' />
              <h6 className='custom_links'>Add Shareholder</h6>
            </div>
          </div>

          {/* Ultimate Beneficial Owners */}
          <div onClick={() => toggleModal(0, true)} className='mt-2 mb-3' style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }}>
            <div style={{ height: 30, width: 30, borderRadius: 100, background: "orange", display: "flex", justifyContent: "center", alignItems: "center" }}>
              <svg id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" className="styles_headerImg__2HEm_"><linearGradient id="TIE_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stopColor="#addcff"></stop><stop offset="0.5028" stopColor="#eaf6ff"></stop><stop offset="1" stopColor="#eaf6ff"></stop></linearGradient><linearGradient id="TIE_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="407" y2="105"><stop offset="0" stopColor="#00c0ff"></stop><stop offset="1" stopColor="#5558ff"></stop></linearGradient><g><g><circle cx="256" cy="256" fill="url(#TIE_1_)" r="256"></circle></g></g><g><g><g><g><path d="m367.345 106.67c-2.307-1.663-5.273-2.115-7.971-1.216l-103.374 34.458-103.374-34.458c-2.698-.899-5.665-.447-7.971 1.216-2.307 1.663-3.674 4.333-3.674 7.177v35.391c0 2.346.932 4.597 2.591 6.256l53.086 53.086c1.727 1.728 3.992 2.592 6.256 2.592s4.529-.864 6.256-2.592l46.83-46.83 46.83 46.83c1.727 1.728 3.992 2.592 6.256 2.592s4.529-.864 6.256-2.592l53.086-53.086c1.659-1.659 2.592-3.91 2.592-6.256v-35.391c0-2.844-1.368-5.514-3.675-7.177zm-83.379 108.072-27.966-27.967-27.966 27.967-16.219 147.052c-.295 2.666.637 5.326 2.536 7.226l35.391 35.391c1.728 1.729 3.993 2.59 6.258 2.59s4.53-.861 6.258-2.59l35.391-35.391c1.899-1.899 2.831-4.559 2.536-7.226z" fill="url(#TIE_2_)"></path></g></g></g></g></svg>
            </div>
            <h6 className='mt-2 mx-2 fw-bold'>Ultimate Beneficial Owners</h6>
          </div>

          <Table
            dataSource={ownersData ? ownersData : []}
            columns={unlimitedBenifialTableColumns}
            scroll={{ x: 'max-content' }}
            expandedRowRender={expandedRowRender}
            expandedRowKeys={expandedRowKeys}
            rowKey="sNo" // Ensure your data has a unique key
            onExpand={(expanded, record) => {
              handleExpandClick(record.sNo); // This will toggle the expanded state
            }}
          />

          <div className='mt-2 my-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
            <div className='d-flex'>
            </div>
            <div className='d-flex justify-content-between align-items-center gap-2 mx-3' onClick={() => {
              setFormOwnerData({
                id:'',
                ownerName: '',
                email: '',
                nationality: '',
                address: '',
                beneficialOwnership: ''
              })
              toggleOwnerModal(0, true);
            }}>
              <i className='fa fa-plus text-black mb-2 custom_add_icon' />
              <h6 className='custom_links'>Add Beneficial Owner</h6>
            </div>
          </div>
        </div>
      </div>
      {/* modal 1 */}
      <Modal
        title={isEditing ? "Edit Shareholder":"Add shareholder"}
        open={isModalOpen[0]}
        onOk={isEditing ? handleEdit : handleOk}
        onCancel={() => toggleModal(0, false)}
        classNames={classNames}
        styles={modalStyles}
      >
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Shareholder Name</label>
          <Input
            name="name"
            value={formData.name}
            onChange={handleChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Email</label>
          <Input
            name="email"
            value={formData.email}
            onChange={handleChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Phone</label>
          <Input
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Share Type</label>
          <Select
            name="shareType"
            value={formData.shareType}
            onChange={handleSelectChange}
            style={{ width: "70%", marginLeft: 30 }}
          >
            <Select.Option name="shareType" value="ordinary">Ordinary</Select.Option>
            <Select.Option name="shareType" value="preferred">Preferred</Select.Option>
          </Select>
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Shares</label>
          <Input
            name="shares"
            value={formData.shares}
            onChange={handleChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Amount</label>
          <Input
            name="amount"
            value={formData.amount}
            onChange={handleChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Currency</label>
          <Select
            name="currency"
            value={formData.currency}
            onChange={handleSelectChange}
            style={{ width: "70%", marginLeft: 30 }}
          >
            {
              currenciesList?.map((currency, index) => {
                return (
                  <Select.Option name="currency" value={currency.code}>{currency.name}</Select.Option>
                )
              })
            }
          </Select>
        </div>
      </Modal>


      {/*  */}
      <Modal
        title={isOwnerEditing ? "Edit Beneficial Owner":"Add Beneficial Owner"}
        open={isOwnerModalOpen[0]}
        onOk={isOwnerEditing ? handleOwnerEdit:handleOwnerOk}
        onCancel={() => toggleOwnerModal(0, false)}
        classNames={classNames}
        styles={modalStyles}
      >
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Name</label>
          <Input
            name="ownerName"
            value={formOwnerData.ownerName}
            onChange={handleOwnerChange}
            placeholder="Enter shareholder"
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Email</label>
          <Input
            name="email"
            value={formOwnerData.email}
            onChange={handleOwnerChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Nationality</label>
          <Input
            name="nationality"
            value={formOwnerData.nationality}
            onChange={handleOwnerChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Address</label>
          <Input
            name="address"
            value={formOwnerData.address}
            onChange={handleOwnerChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Percentage</label>
          <Input
            name="beneficialOwnership"
            value={formOwnerData.beneficialOwnership}
            onChange={handleOwnerChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
      </Modal>
    </>
  )
}

export default Shareholders