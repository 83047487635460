import React, { useState } from 'react';
import { Tabs } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import 'antd/dist/reset.css'; // Ensure antd CSS is imported
import Particulars from '../Particulars/Particulars';
import CddAndKyc from '../CddAndKyc/CddAndKyc';
import Shareholders from '../Shareholders/Shareholders';
import DirectorAndExecutives from '../DirectorAndExecutives/DirectorAndExecutives';
import KeyRelationships from '../KeyRelationships/KeyRelationships';
import ImportantDocuments from '../ImportantDocuments/ImportantDocuments';
import ConfidentialInformation from '../ConfidentialInformation/ConfidentialInformation';
import CustomImportantDocuments from '../CustomImportantDocuments/CustomImportantDocuments';

const PermanentFile = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const onChange = (key) => {
        console.log(key);
    };

    const items = [
        {
            key: '1',
            label: 'Particulars',
            children: <Particulars />,
        },
        {
            key: '2',
            label: 'CDD & KYC',
            children: <CddAndKyc />,
        },
        {
            key: '3',
            label: 'Shareholders',
            children: <Shareholders />,
        },
        {
            key: '4',
            label: 'Directors and Executives',
            children: <DirectorAndExecutives />,
        },
        {
            key: '5',
            label: 'Key Relationships',
            children: <KeyRelationships />,
        },
        {
            key: '6',
            label: 'Important Documents',
            children: <ImportantDocuments />,
        },
        {
            key: '7',
            label: 'Confidential Information',
            children: <ConfidentialInformation />,
        },
        {
            key: '8',
            label: 'Important Documents (Custom)',
            children: <CustomImportantDocuments />,
        },
    ];

    const handleSignOut = () => {
        localStorage.clear(); // Remove all items from local storage
        navigate('/login'); // Navigate to the login page
    };

    return (
        <div>
            <div style={{ width: "100%", height: 50 }}>
                <Tabs
                    defaultActiveKey="1"
                    items={items}
                    onChange={onChange}
                    style={{ height: "100%", width: "100%", backgroundColor: "white", fontSize: '10px' }}
                    animated
                    tabBarStyle={{ fontSize: '10px' }}

                />


                
            </div>
        </div>
    );
};

export default PermanentFile;