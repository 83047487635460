import * as React from "react";
import {
  Box,
  Card,
  CardHeader,
  Grid,
  Chip,
  Badge,
  IconButton,
  Typography,
  CardContent,
  CardActions,
  Avatar,
} from "@mui/material";
import { Draggable } from "react-beautiful-dnd";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import NotificationsNoneIcon from "@mui/icons-material/NotificationsNone";
import { styled } from "@mui/system";


const Title = styled("div")(() => ({
  marginBottom: "1.5px",
  color: "#666666",
}));

const SubTitle = styled("span")(() => ({
  marginBottom: "1.5px",
  color: "#333333",
  fontWeight: "bold",
}));
const Heading = styled("div")(() => ({
  color: "#333333",
  fontWeight: "bold",
  fontSize: "16px",
}));



const TaskCard = ({ item, index }) => {
  return (
    <Draggable key={item.id} draggableId={item.id} index={index}>
      {(provided) => (
        <div
          ref={provided.innerRef}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
        >
          <Card sx={{ minWidth: 275, m: "8px 1px" }}>

            <CardContent sx={{ p: "0 16px", position: "relative" }}>
              <IconButton style={{ position: "absolute", right: 10 }}>
                <MoreVertIcon />
              </IconButton>
              <Typography
                sx={{ fontSize: 14 }}
                color="text.secondary"
                style={{ marginTop: 10 }}
              >
                {item.task}
              </Typography>
              <Box sx={{ flexGrow: 1, color: "#333333", m: "20px 0 0" }}>
                <Grid
                  container
                  spacing={{}}
                  columns={{ xs: 4, sm: 8, md: 12 }}
                >
                  <Grid item xs={12} sm={12} md={12} key={index} style={{ display: "flex", flexDirection: "row" }}>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"
                    >
                      Audit :
                    </Typography>
                    <Typography
                      sx={{ fontSize: 14 }}
                      color="text.secondary"

                    >
                      {item.due_Date}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </CardContent>
            <CardActions>
              <Chip
                label={"AA"}
                sx={{
                  m: 1,
                  minWidth: "25px",
                  maxHeight: "25px",
                  background: "#EEFFF3",
                  color: "#1CA13E",
                  borderRadius: 100
                }}
                variant="outlined"
              />
            </CardActions>
          </Card>
        </div>
      )}
    </Draggable>
  );
};
export default TaskCard;