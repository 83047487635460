import React from 'react';
import { List, Card, Typography, Space } from 'antd';
import { CheckOutlined, FileOutlined, UserOutlined } from '@ant-design/icons';

const { Text, Link } = Typography;

const data = [
  {
    icon: <CheckOutlined style={{ color: '#595959' }} />,
    title: (
      <>
        Aqeel Alam prepared <Link href="#">Lead Schedule</Link> in <Link href="#">Property, Plant & Equipment and Intangible Assets</Link>
      </>
    ),
    date: '11/9/2024, 7:11:13 PM',
  },
  {
    icon: <CheckOutlined style={{ color: '#595959' }} />,
    title: (
      <>
        Aqeel Alam signed off <Link href="#">Preliminary Activities</Link> in <Link href="#">Preliminary Activities</Link>
      </>
    ),
    date: '11/9/2024, 7:10:33 PM',
  },
  {
    icon: <CheckOutlined style={{ color: '#595959' }} />,
    title: (
      <>
        Aqeel Alam reviewed <Link href="#">Preliminary Activities</Link> in <Link href="#">Preliminary Activities</Link>
      </>
    ),
    date: '10/26/2024, 10:29:01 AM',
  },
  {
    icon: <UserOutlined style={{ color: '#595959' }} />,
    title: (
      <>
        Aqeel Alam assigned <Text strong>Aqeel Alam</Text> to <Link href="#">Preliminary Activities</Link>
      </>
    ),
    date: '10/26/2024, 10:27:15 AM',
  },
  {
    icon: <FileOutlined style={{ color: '#595959' }} />,
    title: 'Aqeel Alam added Materiality Calculation.pdf, files',
    date: '7/1/2024, 12:10:12 PM',
  },
  {
    icon: <FileOutlined style={{ color: '#595959' }} />,
    title: 'Aqeel Alam added client acceptance and independence.pdf, files',
    date: '7/1/2024, 11:31:40 AM',
  },
  {
    icon: <FileOutlined style={{ color: '#595959' }} />,
    title: 'Aqeel Alam added Independent Confirmation Sign of.pdf, files',
    date: '7/1/2024, 11:25:32 AM',
  },
  {
    icon: <FileOutlined style={{ color: '#595959' }} />,
    title: 'Aqeel Alam added DCM Engagement Letter.pdf, files',
    date: '7/1/2024, 11:23:08 AM',
  },
  {
    icon: <CheckOutlined style={{ color: '#595959' }} />,
    title: (
      <>
        Aqeel Alam prepared <Link href="#">Preliminary Activities</Link> in <Link href="#">Preliminary Activities</Link>
      </>
    ),
    date: '7/1/2024, 11:00:01 AM',
  },
];

const ActivityList = () => (
  <Card style={{ width: 600, borderRadius: 10 }}>
    <List
      itemLayout="horizontal"
      dataSource={data}
      renderItem={(item) => (
        <List.Item>
          <List.Item.Meta
            avatar={<Space>{item.icon}</Space>}
            title={item.title}
            description={item.date}
          />
        </List.Item>
      )}
    />
  </Card>
);

export default ActivityList;
