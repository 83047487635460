import React from 'react';
import { Form, Input, Button, Typography } from 'antd';
import { useHistory, useNavigate } from 'react-router-dom';
import './Login.css';
import axios from 'axios';
import { errorNotification, successNotification } from '../../common/Alerts';

const { Title, Link } = Typography;

const Login = () => {
    const navigate = useNavigate();

    // const onFinish = (values) => {
    //     const options = {
    //         url: "https://auditpilot.cloudistic.net/api/Auth/login",
    //         method: "POST",
    //         headers: {
    //           Accept: "application/json",
    //           "Content-Type": "application/json;charset=UTF-8",
    //            "Access-Control-Allow-Origin": "*",
    // "Access-Control-Allow-Methods": "GET,PUT,POST,DELETE,PATCH,OPTIONS"
    //         },
    //         data: {
    //           username: values.email,
    //           password: values.password,
    //         },
    //       };


    //       axios(options)
    //       .then((response) => {
    //        console.log("response :: ",response)
    //       })
    //       .catch((error) => {
    //        console.log("error",error)
    //       });
    //     console.log('Success:', values);
    //     // navigate('/dashboard'); 
    // };
    const onFinish = (values) => {
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify({
                username: values.email,
                password: values.password,
            }),
        };
    
        fetch("https://auditpilot.cloudistic.net/api/Auth/login", options)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                localStorage.setItem("email", data?.email);
                localStorage.setItem("expiration", data?.expiration);
                localStorage.setItem("token",data?.token);
             
                // successNotification("Login Successfully!");
                navigate('/dashboard')
                // Handle success response
            })
            .catch((error) => {
                console.log("error", error);
                errorNotification("Please Enter Valid Email/Password")
                // Handle error response
            });
    
        // navigate('/dashboard'); 
    };
    return (
        <div className="login-container">
            <div className="login-form">
                <div className="logo-container">
                    <img src="https://app.smartaudit.co/static/media/logo.7c670ecf.png" alt="Logo" className="logo" />
                </div>
                <Title level={3} style={{ color: '#5ed6b3' }}>
                    LOG IN
                </Title>
                <Form
                    name="login"
                    layout="vertical"
                    onFinish={onFinish}
                    style={{ width: '100%' }}
                >
                    <Form.Item
                        name="email"
                        label="Username"
                        // rules={[
                        //     {
                        //         required: true,
                        //         message: 'Please input your email!',
                        //         type: 'email',
                        //     },
                        // ]}
                    >
                        <Input className="rounded-input" />
                    </Form.Item>
                    <Form.Item
                        name="password"
                        label="Password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your password!',
                            },
                        ]}
                    >
                        <Input.Password className="rounded-input"  />
                    </Form.Item>
                    <Form.Item>
                        <Button
                            type="primary"
                            htmlType="submit"
                            block
                            style={{
                                backgroundColor: '#36cfc9',
                                borderColor: '#36cfc9',
                                height: '40px',
                            }}
                        >
                            SIGN-IN
                        </Button>
                    </Form.Item>
                    <div style={{ textAlign: 'center' }}>
                        <Link href="#" style={{ color: '#36cfc9' }}>
                            Forgot Password?
                        </Link>
                    </div>
                </Form>
            </div>
        </div>
    );
};

export default Login;