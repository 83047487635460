import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router-dom";
import "./App.css";

import "react-toastify/dist/ReactToastify.css";
import { ToastContainer } from "react-toastify";
import Login from "./pages/Login/Login";
import Dashboard from "./pages/Dashboard/Dashboard";
import Clients from "./pages/Clients/Clients";
import NewClient from "./pages/NewClient/NewClient";
import Jobs from "./pages/Jobs/Jobs";
import AuthRoute from "./AuthRoute/AuthRoute"; // import AuthRoute
import FirmDetails from "./pages/FirmDetails/FirmDetails";
import Security from "./pages/Security/Security";
import BillingAndSubscriptions from "./pages/BillingAndSubscriptions/BillingAndSubscriptions";
import WorkFile from "./pages/WorkFile/WorkFile";

const App = () => {
  const navigate = useNavigate()
  useEffect(() => {
    const token = localStorage.getItem('token');
    const expiration = localStorage.getItem('expiration');
    const email = localStorage.getItem('email');

    if (token && expiration && email) {
      const expirationDate = new Date(expiration);
      const currentDate = new Date();

      if (currentDate > expirationDate) {
        localStorage.clear();
        navigate('/login');
      } else {
        // navigate('/clients');
      }
    } else {
      navigate('/login');
    }
  }, []);
  return (
    <>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />
        
        {/* Protected Routes */}
        <Route
          path="/dashboard"
          element={
            <AuthRoute>
              <WorkFile />
            </AuthRoute>
          }
        />
        <Route
          path="/jobs"
          element={
            <AuthRoute>
              <Jobs />
            </AuthRoute>
          }
        />
        <Route
          path="/clients"
          element={
            <AuthRoute>
              <Clients />
            </AuthRoute>
          }
        />
        <Route
          path="/new-client/:clientId"
          element={
            <AuthRoute>
              <NewClient />
            </AuthRoute>
          }
        />
        <Route
          path="/workfile/:workFileId"
          element={
            <AuthRoute>
              <WorkFile />
            </AuthRoute>
          }
        />
        <Route
          path="/firm-details"
          element={
            <AuthRoute>
              <FirmDetails />
            </AuthRoute>
          }
        />
        <Route
          path="/security"
          element={
            <AuthRoute>
              <Security />
            </AuthRoute>
          }
        />
        <Route
          path="/billing-and-subscriptions"
          element={
            <AuthRoute>
              <BillingAndSubscriptions />
            </AuthRoute>
          }
        />
      </Routes>

      <ToastContainer
        position="top-center"
        autoClose={2500}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover={false}
      />
    </>
  );
};

export default App;
