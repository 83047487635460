import React, { useEffect, useState } from 'react';
import moment from 'moment';
import TableArea from '../../components/TableArea';
import { Button, Checkbox, Menu, message, Progress, Slider, Table, Upload } from 'antd';
import { Form, Input, Select, DatePicker, Row, Col, Modal } from 'antd';
import { KYCTableColumns, sampleClientsData } from '../../utils/clientsUtils';
import StepperComponent from '../../components/StepperComponent';
import { createStyles, useTheme } from 'antd-style';
import { Link, useParams } from 'react-router-dom';
import Dragger from 'antd/es/upload/Dragger';
import currenciesList from '../../utils/currenciesList';
import axiosInstance from '../../apiIntecepter/axiosInstance';

const useStyle = createStyles(({ token }) => ({
  'my-modal-body': {
    background: token.colorWhite,
    padding: token.paddingSM,
  },
  'my-modal-mask': {
    boxShadow: `inset 0 0 15px #fff`,
  },
  'my-modal-header': {
    borderBottom: `1px dotted ${token.colorPrimary}`,
  },
  'my-modal-footer': {
    color: token.colorPrimary,
  },
  'my-modal-content': {
    border: '1px solid #333',
  },
}));

const DirectorAndExecutives = () => {
  const { styles } = useStyle();
  const { clientId } = useParams();
  const [isEditing, setIsEditing] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const token = useTheme();
  const [isModalOpen, setIsModalOpen] = useState([false, false]);
  const [isOwnerModalOpen, setIsOwnerModalOpen] = useState([false, false]);
  const { confirm } = Modal;
  const [directorsData, setDirectorsData] = useState([])
  const [formData, setFormData] = useState({
    id: '',
    fullName: '',
    email: '',
    phone: '',
    nationality: '',
    address: '',
    ownership: 0, // Changed to number
    IdNo: "string",
    position: '',
    appointedOn: null,
    resignedOn: null,
  });

  const toggleModal = (index, isOpen, client = null) => {
    const newModalState = [...isModalOpen];
    newModalState[index] = isOpen;
    setIsModalOpen(newModalState);
    setIsEditing(!!client);
    setCurrentClient(client);
  };

  const classNames = {
    body: styles['my-modal-body'],
    mask: styles['my-modal-mask'],
    header: styles['my-modal-header'],
    footer: styles['my-modal-footer'],
    content: styles['my-modal-content'],
  };

  const modalStyles = {
    header: {
      borderLeft: `5px solid ${token.colorPrimary}`,
      borderRadius: 0,
      paddingInlineStart: 5,
    },
    body: {
      borderRadius: 5,
    },
    mask: {},
    footer: {
      // borderTop: '1px solid #333',
    },
    content: {
      boxShadow: '0 0 30px #999',
    },
  };

  const handleOk = () => {
    console.log(formData)
    axiosInstance.post(`/Client/${clientId}/directors`, formData).then((response) => {
      setDirectorsData([...directorsData, response?.data])
      toggleModal(0, false)
    }).catch((error) => { console.log("err", error) })
  };

  const handleEdit = () => {
    let body = {
      "fullName": formData.fullName,
      "email": formData.email ? formData.email : "",
      "phone":formData.phone ? formData.phone : "",
      "nationality":formData.nationality ? formData.nationality : "",
      "idNo": formData.IdNo ? formData.IdNo : "",
      "address": formData.address ? formData.address : "",
      "position": formData.position ? formData.position : "",
      "ownership": formData.ownership ? formData.ownership : "",
      "appointedOn": formData.appointedOn ? moment(formData.appointedOn) : "",
      "resignedOn": formData.resignedOn ? moment(formData.resignedOn) : "",
    }
    console.log(formData)
    axiosInstance.put(`/Client/${clientId}/directors/${currentClient?.id}`, body).then((response) => {
      setDirectorsData(directorsData?.map(note => 
        note?.id === response?.data?.id ? response?.data : note
      ));
      toggleModal(0, false)
    }).catch((error) => { console.log("err", error) })
  };

  const showDeleteConfirm = (isNote,record) => {
    confirm({
      title: 'Remove User',
      content: `Are you sure you want to delete this Directors`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        console.log('OK');
        axiosInstance.delete(`/Client/${clientId}/directors/${record?.id}`).then((response) => {
          setDirectorsData(directorsData.filter((item) => item.id !== record?.id))
          toggleModal(0, false)
        }).catch((error) => { console.log("err", error) })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleEditClick = (record, isOwner) => {
    setFormData({
      fullName: record?.fullName,
      nationality: record?.nationality,
      address: record?.address,
      ownership: record?.ownership,
      IdNo: record?.IdNo,
      position: record?.position,
      appointedOn: record?.appointedOn ? moment(record.appointedOn) : null,
      resignedOn: record?.resignedOn ? moment(record.resignedOn) : null,
    });
    toggleModal(0, true, record);
  };

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleExpandClick = (recordId) => {
    console.log("recordId ::: ", recordId)
    setExpandedRowKeys((prevKeys) => {
      if (prevKeys.includes(recordId)) {
        // If the row is already expanded, remove it from the keys (collapse)
        return prevKeys.filter(key => key !== recordId);
      } else {
        // If the row is not expanded, collapse all others and expand this one
        return [recordId];
      }
    });
  };

  const handleDateChange = (date, dateString, field) => {
    setFormData({
      ...formData,
      [field]: date ? moment(dateString) : null,
    });
  };

  const shareholderTableColumns = [
    {
      title: 'S No',
      dataIndex: 'id',
      key: 'id',
    },
    {
      title: 'Name',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (text, record) => {
        console.log("record", record)
        return (
          <div>
            <span>
              <span to={`/new-client/${record?.id}`}>
                {record?.fullName}
              </span>
            </span>
          </div>
        )
      }
    },

    {
      title: 'Nationality',
      dataIndex: 'nationality',
      key: 'nationality',
    },
    {
      title: 'Address',
      dataIndex: 'address',
      key: 'address',
    },
    {
      title: 'Percentage',
      dataIndex: 'ownership',
      key: 'ownership',
      render: (text) => <Progress type='circle' percent={Number(text)} width={40} />,
    },
    {
      title: 'Position',
      dataIndex: 'position',
      key: 'position',
    },
    {
      title: 'Date of Appointment',
      dataIndex: 'appointedOn',
      key: 'appointedOn',
      render: (text) => moment(text).format('YYYY-MM-DD'),
    },
    {
      title: 'Date of Resignation',
      dataIndex: 'resignedOn',
      key: 'resignedOn',
      render: (text) => moment(text).format('YYYY-MM-DD'),
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <span>
          <i className='fa fa-edit text-primary mx-3' onClick={() => handleEditClick(record, false)} />
          <i className='fa fa-trash text-danger' onClick={() => { showDeleteConfirm(false,record) }} />
        </span>
      ),
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const expandedRowRender = (record) => {
    const { Dragger } = Upload;
    const props = {
      name: 'file',
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e77',
      onChange(info) {
        const { status } = info.file;
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      onDrop(e) {
        console.log('Dropped files:', e.dataTransfer.files);
      },
    };

    return (
      <tr>
        <td colSpan="8">
          <Dragger {...props} style={{ width: 600 }}>
            <p className="ant-upload-drag-icon">
              <i className='fa fa-plus' />
            </p>
            <p className="ant-upload-text">Upload Media</p>
            {/* <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              sensitive information.
            </p> */}
          </Dragger>
        </td>
      </tr>
    );
  };

  const sampleShareholdersData = [
    {
      sNo: 1,
      name: 'John Doe',
      nationality: 'American',
      address: '123 Main St, Anytown, USA',
      percentage: 25,
      position: 'CEO',
      appointedOn: '2020-01-01',
      resignedOn: '2023-01-01',
    },
    {
      sNo: 2,
      name: 'Jane Smith',
      nationality: 'British',
      address: '456 Elm St, Othertown, UK',
      percentage: 50,
      position: 'CFO',
      appointedOn: '2019-05-15',
      resignedOn: '2022-12-31',
    },
  ];

  const handleSelectChange = (value, option) => {
    const { name } = option.props;
    setFormData({
      ...formData,
      [name]: value
    });
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const contactsResponse = await axiosInstance.get(`/Client/${clientId}/directors`);
        setDirectorsData(contactsResponse?.data);
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <div className='container'>
        {/* Directors and Executives */}
        <div className='mt-2 mb-3' style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }}>
          <div style={{ height: 30, width: 30, borderRadius: 100, background: "orange", display: "flex", justifyContent: "center", alignItems: "center" }}>
            <svg id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" className="styles_headerImg__1L4uI"><linearGradient id="GROUP_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stopColor="#addcff"></stop><stop offset="0.5028" stopColor="#eaf6ff"></stop><stop offset="1" stopColor="#eaf6ff"></stop></linearGradient><linearGradient id="GROUP_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="407" y2="105"><stop offset="0" stopColor="#00c0ff"></stop><stop offset="1" stopColor="#5558ff"></stop></linearGradient><g><g><g><circle cx="256" cy="256" fill="url(#GROUP_1_)" r="256"></circle></g></g><g id="Group_3_"><g><g><g><path d="m247.118 238.235c0-34.28-27.896-62.176-62.177-62.176s-62.176 27.896-62.176 62.176 27.896 62.176 62.176 62.176 62.177-27.895 62.177-62.176zm-62.177 62.177c-44.082 0-79.941 35.859-79.941 79.941v17.765c0 4.91 3.973 8.882 8.882 8.882h142.118c4.91 0 8.882-3.973 8.882-8.882v-17.765c0-44.082-35.859-79.941-79.941-79.941zm204.294-133.236c0-34.28-27.896-62.176-62.177-62.176-34.28 0-62.177 27.896-62.177 62.176s27.896 62.176 62.177 62.176c34.281.001 62.177-27.895 62.177-62.176zm-62.176 62.177c-44.082 0-79.941 35.859-79.941 79.941v17.765c0 4.91 3.973 8.882 8.882 8.882h142.118c4.91 0 8.882-3.973 8.882-8.882v-17.765c0-44.082-35.859-79.941-79.941-79.941z" fill="url(#GROUP_2_)"></path></g></g></g></g></g></svg>
          </div>
          <h6 className='mt-2 mx-2 fw-bold'>Directors and Executives</h6>
        </div>

        <Table
          dataSource={directorsData ? directorsData : []}
          columns={shareholderTableColumns}
          scroll={{ x: 'max-content' }}
          expandedRowRender={expandedRowRender}
          expandedRowKeys={expandedRowKeys}
          rowKey="sNo" // Ensure your data has a unique key
          onExpand={(expanded, record) => {
            handleExpandClick(record?.sNo); // This will toggle the expanded state
          }}
        />

        <div className='mt-2 my-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
          <div className='d-flex'>
          </div>
          <div className='d-flex justify-content-between align-items-center gap-2 mx-3' onClick={() => {
            setFormData({
              fullName: '',
              nationality: '',
              address: '',
              ownership: '',
              IdNo: "",
              position: '',
              appointedOn: null,
              resignedOn: null,
            });
            toggleModal(0, true);
          }}>
            <i className='fa fa-plus text-black mb-2 custom_add_icon' />
            <h6 className='custom_links'>Add Director</h6>
          </div>
        </div>
      </div>

      {/* modal 1 */}
      <Modal
        title={isEditing ? "Edit Director" : "Add Director"}
        open={isModalOpen[0]}
        onOk={isEditing ? handleEdit: handleOk}
        onCancel={() => toggleModal(0, false)}
        classNames={classNames}
        styles={modalStyles}
      >
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Name</label>
          <Input
            name="fullName"
            value={formData.fullName}
            onChange={handleChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Email</label>
          <Input
            name="email"
            required
            value={formData.email}
            onChange={handleChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Phone</label>
          <Input
            name="phone"
            value={formData.phone}
            onChange={handleChange}
            required
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Nationality</label>
          <Input
            name="nationality"
            value={formData.nationality}
            onChange={handleChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Address</label>
          <Input
            name="address"
            value={formData.address}
            onChange={handleChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Percentage</label>
          <Slider
            name="ownership"
            value={formData.ownership}
            onChange={(value) => handleChange({ target: { name: 'ownership', value } })}
            min={0}
            max={100}
            marks={{
              0: '0',
              20: '20',
              40: '40',
              60: '60',
              80: '80',
              100: '100',
            }}
            style={{ width: "68%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Position</label>
          <Select
            name="position"
            value={formData.position}
            onChange={(value) => handleChange({ target: { name: 'position', value } })}
            style={{ width: "70%", marginLeft: 30 }}
          >
            <Select.Option value="Director">Director</Select.Option>
            <Select.Option value="Secretary">Secretary</Select.Option>
            <Select.Option value="Executive">Executive</Select.Option>
          </Select>
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Date of Appointment</label>
          <DatePicker
            name="appointedOn"
            value={formData.appointedOn}
            onChange={(date, dateString) => handleDateChange(date, dateString, 'appointedOn')}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Date of Resignation</label>
          <DatePicker
            name="resignedOn"
            value={formData.resignedOn}
            onChange={(date, dateString) => handleDateChange(date, dateString, 'resignedOn')}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
      </Modal>
    </>
  )
}

export default DirectorAndExecutives;