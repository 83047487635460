import React, { useState } from 'react';
import { Checkbox, Typography } from 'antd';
import moment from 'moment';

const { Title } = Typography;

const App = () => {
    const [prepared, setPrepared] = useState(true);
    const [reviewed, setReviewed] = useState(true);
    const [signedOff, setSignedOff] = useState(true);
    const [secondPartnerSignedOff, setSecondPartnerSignedOff] = useState(false);

    return (
        <table width="100%" style={{ boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', paddingRight: 10, paddingLeft: 10, marginBottom: 10 }} >
            <tbody id="audit-table" >
                <tr >
                    <td width="10%" className='px-3'>
                        <Checkbox checked={prepared} >
                            <h6 style={{ marginTop: 10 }}>Prepared</h6>
                        </Checkbox>
                    </td>
                    <td style={{ width: '10%' }}>
                        {/* <div style={{ margin: 10,}}>
              Aqeel Alam<br />01 July 2024
            </div> */}
                    </td>
                    <td width="10%">
                        <Checkbox checked={reviewed} >
                            <h6 style={{ marginTop: 10 }}>Reviewed</h6>
                        </Checkbox>
                    </td>
                    <td style={{ width: '10%' }}>
                        {/* <div style={{ margin: 10,}}>
              Aqeel Alam<br />26 October 2024
            </div> */}
                    </td>
                    <td width="12%">
                        <Checkbox checked={signedOff} disabled>
                            <h6 level={6} style={{ marginTop: 10 }}>Signed Off</h6>
                        </Checkbox>
                    </td>
                    <td style={{ width: '10%' }}>
                        {/* <div style={{ margin: 10, }}>
              Aqeel Alam<br />09 November 2024
            </div> */}
                    </td>
                    <td>
                        <Checkbox checked={secondPartnerSignedOff}>
                            <span>
                                <h6 style={{ marginTop: 10 }}>Signed Off</h6>
                                <h6 style={{}}>(Second partner)</h6>
                            </span>
                        </Checkbox>
                    </td>
                    <td>
                        {/* <div style={{ margin: 10,}}>
              Aqeel Alam<br />09 November 2024
            </div> */}
                    </td>
                    <td width="15%">
                        <Checkbox disabled>
                            <h6 style={{ marginTop: 10 }}>Not Applicable</h6>
                        </Checkbox>
                    </td>
                    <td style={{ width: '5%' }}>
                        <div className="d-flex justify-content-center align-items-center">
                            <div className="cursor-pointer">
                                <span><i className="fas fa-lock-open fa-lg"></i></span>
                            </div>
                        </div>
                    </td>
                </tr>
            </tbody>
        </table>
    );
};

export default App;