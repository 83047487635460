import { ToastContainer, toast } from "react-toastify";

// notifications
const errorNotification = (message,position) => {
  toast.error(message, {
    position: position?position:"bottom-right",
    autoClose: 1000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};

const successNotification = (message,position) => {
  toast.success(message, {
    position: position?position:"bottom-right",
    autoClose: 2500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: false,
    draggable: true,
    progress: undefined,
  });
};

export {successNotification,errorNotification}
