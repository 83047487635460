import React from 'react';
import CustomProgessBar from '../../components/CustomProgessBar';
import CustomCard from '../../components/CustomCard';
import ActivityList from '../../components/ActivityList';

const WorkFileHome = () => {
    const list = [
        {
            id: 1,
            title: "A) Preliminary Activities",
            progressFirst: { done: 0, total: 1 },
            progressSecond: { done: 0, total: 1 },
            progressThird: { done: 0, total: 1 },
            isExpanded: false,
            subCategories: null
        },
            {id: 2,
            title: "B) Risk Assessment",
            progressFirst: { done: 0, total: 1 },
            progressSecond: { done: 0, total: 1 },
            progressThird: { done: 0, total: 1 },
            isExpanded: false,
            subCategories: null
        },
            {id: 3,
            title: "C) Audit Materiality",
            progressFirst: { done: 0, total: 1 },
            progressSecond: { done: 0, total: 1 },
            progressThird: { done: 0, total: 1 },
            isExpanded: false,
            subCategories: null
        },
            {id: 3,
            title: "D) Review of journal entries and other adjustments",
            progressFirst: { done: 0, total: 1 },
            progressSecond: { done: 0, total: 5 },
            progressThird: { done: 0, total: 3 },
            isExpanded: false,
            subCategories: null
        },
            {id: 4,
            title: "E) Property, Plant & Equipment and Intangible Assets",
            progressFirst: { done: 0, total: 1 },
            progressSecond: { done: 0, total: 1 },
            progressThird: { done: 0, total: 1 },
            isExpanded: true,
            subCategories: [
                {
                    id: 1,
                    title: "E1) Lead Schedule",
                    progressFirst: true,
                    progressSecond: false,
                    progressThird: true,

                },
                {
                    id: 1,
                    title: "E2) Field Work",
                    progressFirst: true,
                    progressSecond: false,
                    progressThird: true,

                },
                {
                    id: 1,
                    title: "E3) Disclosure note",
                    progressFirst: true,
                    progressSecond: false,
                    progressThird: true,

                }
            ]
        },

    ]
    return (
        <div style={{ display: 'flex' }}>
            <div style={{ flex: '0 0 60%', }}>
                {
                    list?.map(item => (
                        <CustomCard item={item} />
                    ))
                }

            </div>
            <div style={{ flex: '0 0 40%', }}>
                <ActivityList />
            </div>
        </div>
    );
}

export default WorkFileHome;