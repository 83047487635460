import React, { useEffect, useState } from 'react';
import { Upload, Card, Typography, message } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import axiosInstance from '../../apiIntecepter/axiosInstance';

const { Title } = Typography;

const UploadCard = ({ title, fieldName, UploadedPicture, setIsFetchAgain, isFetchAgain }) => {
  const { clientId } = useParams();
  const [fileList, setFileList] = useState([]);

  const handleChange = async ({ fileList }) => {
    setFileList(fileList);

    const formData = new FormData();
    fileList.forEach(file => {
      formData.append(fieldName, file.originFileObj);
    });

    try {
      axiosInstance.post(`/Client/${clientId}/upload-documents`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      })
        .then(response => {
          console.log('Document uploaded successfully:', response.data);
          setFileList([]);
          setIsFetchAgain(!isFetchAgain)
        })
        .catch(error => {
          console.error('Error uploading document:', error);
        });
    } catch (error) {
      message.error('Upload failed.');
    }
  };

  return (
    <Card bordered={false} style={{ width: '70%', border: '1px dashed #d9d9d9', marginBottom: 20 }}>
      <Title level={5} style={{ color: '#4CAF50' }}>{title}</Title>
      <Upload.Dragger
        style={{ padding: '40px 0' }}
        showUploadList={false}
        fileList={fileList}
        onChange={handleChange}
      >
        <PlusOutlined style={{ fontSize: 24 }} />
        <div>Upload Files</div>
      </Upload.Dragger>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: 20 }}>
        {fileList.map((file, index) => (
          <div key={index} style={{ margin: 10 }}>
            <img
              src={URL.createObjectURL(file.originFileObj)}
              alt="uploaded"
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
            <div>{file.name}</div>
          </div>
        ))}

        {
          UploadedPicture && (
            <div style={{ margin: 10 }}>
              <img
                src={`https://auditpilot.cloudistic.net${UploadedPicture}`}
                alt="uploaded"
                style={{ width: '150px', height: '150px', objectFit: 'cover' }}
              />
            </div>
          )
        }
      </div>
    </Card>
  );
};

const ImportantDocuments = () => {
  const { clientId } = useParams();
  const [confidentialData, setConfidentialData] = useState([])
  const [isFetchAgain, setIsFetchAgain] = useState(false)
  useEffect(() => {
    const fetchConfidentialData = async () => {
      try {
        const confidentialNewData = await axiosInstance.get(`/Client/${clientId}/Documents`);
        console.log(confidentialNewData?.data);
        setConfidentialData(confidentialNewData?.data);
      } catch (error) {
        console.error("Error fetching beneficial owners data:", error);
      }
    };
    fetchConfidentialData();
  }, [isFetchAgain]);


  console.log("confidentialData :::", confidentialData)
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <UploadCard title="Constitution (MOA/AOA)" fieldName="constitution" UploadedPicture={confidentialData?.constitutionFilePath} setIsFetchAgain={setIsFetchAgain} isFetchAgain={isFetchAgain} />
      <UploadCard title="Certificate of Incorporation" fieldName="certificateOfIncorporation" UploadedPicture={confidentialData?.certificateOfIncorporationFilePath} setIsFetchAgain={setIsFetchAgain} isFetchAgain={isFetchAgain} />
      <UploadCard title="Business Profile" fieldName="businessProfile" UploadedPicture={confidentialData?.businessProfileFilePath} setIsFetchAgain={setIsFetchAgain} isFetchAgain={isFetchAgain} />
      <UploadCard title="Group Structure" fieldName="groupStructure" UploadedPicture={confidentialData?.groupStructureFilePath} setIsFetchAgain={setIsFetchAgain} isFetchAgain={isFetchAgain} />
      <UploadCard title="Annual Returns" fieldName="annualReturns" UploadedPicture={confidentialData?.annualReturnsFilePath} setIsFetchAgain={setIsFetchAgain} isFetchAgain={isFetchAgain} />
      <UploadCard title="Financial Statements" fieldName="financialStatements" UploadedPicture={confidentialData?.financialStatementsFilePath} setIsFetchAgain={setIsFetchAgain} isFetchAgain={isFetchAgain} />
      {/* <UploadCard title="Others" fieldName="others" /> */}
    </div>)
}

export default ImportantDocuments;