import { v4 as uuidv4 } from "uuid";

// import "" from "../../../../assets/Recruitment/india."""

export const CandidatesData = [
  {
    id: "1",
    task: "DIGITAL CONVERSIONS MEDIA (PRIVATE) LIMITED ",
    assigned_To: 'Beltran',
    assignee: 'Romona',
    Status: 'To-do',
    priority: 'Low',
    due_Date: "30-June-2023",
  },

];

export const columnsFromBackend = {
  [uuidv4()]: {
    title: 'To start',
    items: CandidatesData,
  },
  [uuidv4()]: {
    title: 'In progress',
    items: [],
  },
  [uuidv4()]: {
    title: 'Under Review',
    items: [],
  },
  [uuidv4()]: {
    title: 'Completed',
    items: [],
  },
  [uuidv4()]: {
    title: 'Archived',
    items: [],
  },
};