import React from 'react';
import CustomHeader from '../../components/CustomHeader';
import CustomProgessBar from '../../components/CustomProgessBar';
import { Tabs } from 'antd';
import PermanentFile from '../PermanentFile/PermanentFile';
import WorkFileHome from '../WorkFileHome/WorkFileHome';
import AuditProgram from '../AuditProgram/AuditProgram';

const MyComponent = () => {

  const onChange = (key) => {
    console.log(key);
  };
  const items = [
    {
      key: '1',
      label: 'Home',
      children: <WorkFileHome />,
    },
    {
      key: '2',
      label: 'PF',
      children: <PermanentFile />,
    },
    {
      key: '3',
      label: 'Audit Program',
      children:<AuditProgram/>,
    },
  ];
  return (

    <>
      <CustomHeader />
      <div className='d-flex justify-content-between align-items-center w-100 px-5 py-2 bg-white'>
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
          <i className="fa fa-building fa-1x" style={{ color: "#219a9e", marginBottom: 5 }}></i>
          <h6 style={{ fontSize: 14, fontWeight: "bold", marginLeft: 5 }}>DIGITAL CONVERSIONS MEDIA (PRIVATE) LIMITED</h6>
        </div>

        <div style={{ width: "40%", display: "flex", justifyContent: "space-between" }}>
          <CustomProgessBar
            titleTop={"Prepared 2/48"}
            color={"bg-info"}
          />
          <CustomProgessBar
            titleTop={"Reviewed 1/48"}
            color={"bg-warning"}
          />
          <CustomProgessBar
            titleTop={"Completed 1/48"}
            color={"bg-success"}
          />
        </div>
      </div>

      <Tabs
        type="card"
        defaultActiveKey="1"
        items={items}
        onChange={onChange}
        animated
        style={{width:"100%",marginTop:30,    backgroundColor:"#f5f5f5"}}
        tabBarStyle={{ color: 'red', alignItems: "flex-end", background: "white" }}
      />

    </>
  )

}

export default MyComponent;