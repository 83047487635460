import React, { useState } from 'react';
import './AuditProgram.css'; // Import the CSS file
import { CSSTransition } from 'react-transition-group';
import HeaderForAudit from '../../components/HeaderForAudit';

const AuditProgram = () => {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);
    const [selectedTab, setSelectedTab] = useState(null);

    const toggleAccordion = (id) => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    const toggleTab = (id) => {
        console.log("id", id);
        setSelectedTab(id);
    }

    const list = [
        {
            id: 1,
            title: "A) Preliminary Activities",
            isExpanded: false,
            subCategories: null
        },
        {
            id: 2,
            title: "B) Risk Assessment",
            isExpanded: false,
            subCategories: null
        },
        {
            id: 3,
            title: "C) Audit Materiality",
            isExpanded: false,
            subCategories: null
        },
        {
            id: 4,
            title: "D) Review of journal entries and other adjustments",
            isExpanded: false,
            subCategories: null
        },
        {
            id: 5,
            title: "E) Property, Plant & Equipment and Intangible Assets",
            isExpanded: true,
            subCategories: [
                {
                    id: 5,
                    title: "E1) Lead Schedule",
                },
                {
                    id: 6,
                    title: "E2) Field Work",
                },
                {
                    id: 7,
                    title: "E3) Disclosure note",
                }
            ]
        },
    ];

    return (
        <div className="audit-program-container">
            <div className="sidebar">
                <ul>
                    {
                        list?.map((item, index) => {
                            return (
                                <>
                                    <li key={index} onClick={() => {
                                        toggleTab(item.id);
                                    }} className="no-select text-secondary">
                                        {item?.title.length > 35 ? item.title.substring(0, 35) + '...' : item.title} {
                                            item?.isExpanded &&
                                            <i onClick={() => { toggleAccordion(item.id); }} className={`fa ${isAccordionOpen ? 'fa-chevron-up' : 'fa-chevron-down'} mb-2 mx-2 hover-effect`}></i>}
                                    </li>
                                    {
                                        item?.isExpanded && isAccordionOpen &&
                                        <CSSTransition
                                            in={item?.isExpanded}
                                            timeout={300}
                                            classNames="accordion"
                                            unmountOnExit
                                        >
                                            <ul className="no-select text-secondary" style={{ marginLeft: 10 }}>
                                                {
                                                    item?.subCategories?.map((subItem, subIndex) => (
                                                        <li key={subIndex} onClick={() => { toggleTab(subItem?.id); }}>{subItem?.title}</li>
                                                    ))
                                                }
                                            </ul>
                                        </CSSTransition>
                                    }
                                </>
                            )
                        })
                    }
                </ul>
            </div>
            <div className="content">
                <HeaderForAudit/>

                <CSSTransition
                    in={selectedTab === 1}
                    timeout={300}
                    classNames="tab-content"
                    unmountOnExit
                >
                    <div>Content for Preliminary Activities</div>
                </CSSTransition>
                <CSSTransition
                    in={selectedTab === 2}
                    timeout={300}
                    classNames="tab-content"
                    unmountOnExit
                >
                    <div>Content for Risk Assessment</div>
                </CSSTransition>
                <CSSTransition
                    in={selectedTab === 3}
                    timeout={300}
                    classNames="tab-content"
                    unmountOnExit
                >
                    <div>Content for Audit Materiality</div>
                </CSSTransition>
                <CSSTransition
                    in={selectedTab === 4}
                    timeout={300}
                    classNames="tab-content"
                    unmountOnExit
                >
                    <div>Content for Review of journal entries and other adjustments</div>
                </CSSTransition>
                <CSSTransition
                    in={selectedTab === 5}
                    timeout={300}
                    classNames="tab-content"
                    unmountOnExit
                >
                    <div>Content for Property, Plant & Equipment and Intangible Assets</div>
                </CSSTransition>
                <CSSTransition
                    in={selectedTab === 6}
                    timeout={300}
                    classNames="tab-content"
                    unmountOnExit
                >
                    <div>6</div>
                </CSSTransition>
                <CSSTransition
                    in={selectedTab === 7}
                    timeout={300}
                    classNames="tab-content"
                    unmountOnExit
                >
                    <div>Content for Property, Plant & Equipment and Intangible Assets</div>
                </CSSTransition>
            </div>
        </div>
    );
};

export default AuditProgram;