import { Table } from 'antd';
import React from 'react';

const TableArea = ({
    clientsData,
    filteredColumns,
    expandedRowRender,
    expandedRowKeys,
    onExpand
}) => {



    return (
        <div className='mx-2'>
            <Table
                dataSource={clientsData}
                columns={filteredColumns}
                scroll={{ x: 'max-content' }}
                expandedRowRender={expandedRowRender}
                expandedRowKeys={expandedRowKeys}
                onExpand={onExpand}
            />
        </div>

    );
}

export default TableArea;