import React, { useState } from 'react';
import { Steps, Button, Input, Upload, Col, Row, Typography, Card } from 'antd';
import 'antd/dist/reset.css';
import { Checkbox, Form, } from 'antd';
import { PlusOutlined, UploadOutlined } from '@ant-design/icons';

const { Step } = Steps;
const { TextArea } = Input;
const { Title, Text } = Typography;




const UploadCard = ({ title }) => {
  const [fileList, setFileList] = useState([]);

  const handleChange = ({ fileList }) => setFileList(fileList);

  return (
    <>
      <Upload.Dragger
        style={{ padding: '20px 0' ,width:"50%",marginTop:10}}
        showUploadList={false}
        fileList={fileList}
        onChange={handleChange}
        multiple
      >
        <PlusOutlined style={{ fontSize: 24 }} />
        <div>Upload Files</div>
      </Upload.Dragger>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: 20 }}>
        {fileList.map((file, index) => (
          <div key={index} style={{ margin: 10 }}>
            <img
              src={URL.createObjectURL(file.originFileObj)}
              alt="uploaded"
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
            <div>{file.name}</div>
          </div>
        ))}
      </div>
    </>
  );
};
const StepperComponent = () => {
  // State to keep track of the current step
  const [current, setCurrent] = useState(0);

  // State to store input values for the first step
  const [serviceType, setServiceType] = useState('');


  const [selectedChecks, setSelectedChecks] = useState([]);
  const [isEnhancedDD, setIsEnhancedDD] = useState(false);
  const [enhancedDDValue, setEnhancedDDValue] = useState('');

  // Handler to toggle the checkboxes
  const handleCheckboxChange = (value) => {
    setSelectedChecks((prev) =>
      prev.includes(value)
        ? prev.filter((item) => item !== value)
        : [...prev, value]
    );
  };

  // Handler for the Enhanced DD checkbox
  const handleEnhancedDDChange = (e) => {
    setIsEnhancedDD(e.target.checked);
    if (!e.target.checked) {
      setEnhancedDDValue(''); // Clear input if unchecked
    }
  };


  // Steps data
  const steps = [
    {
      title: "Nature of services to be provided",
      content: (
        <div>
          <div style={{ marginBottom: 16, display: "flex", flexDirection: "row", justifyContent: "flex-start", alignItems: "center" }}>
            <label style={{ fontWeight: 'bold' }}>Service Type:</label>
            <Input
              placeholder="Enter service type"
              value={serviceType}
              onChange={(e) => setServiceType(e.target.value)}
              style={{ marginTop: 8, width: "50%", marginLeft: 100 }}
            />
          </div>
        </div>
      )
    },
    {
      title:
        "Customer Risk factors",
      content: (
        <div style={{ margin: '0 auto' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <h6 style={{ alignSelf: "flex-start" }}><i className='fa fa-list' style={{ marginRight: 10 }} />Select the applicable</h6>
            <Checkbox onChange={() => handleCheckboxChange('cashTransactions')}>
              Unaccounted cash transactions to similar type of recipient.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('criminalConvictions')}>
              The proposed directors/partners/shareholders have prior criminal convictions involving fraud or dishonesty.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('PEP')}>
              The customer is a Politically Exposed Person (PEP), their family members or known associates.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('terroristProfile')}>
              The customer/his family members or associates are known to have terrorist, criminal, sanctioned individual/entity profile.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('adverseNews')}>
              There is adverse news or information related to the company or client or family members of the client or known associates or has been reported to be involved in any adverse activity.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('complexStructure')}>
              Corporate vehicles that are unnecessarily and unjustifiably complex multi-tiered entities/complex shareholding/business structure, given the nature of company's business.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('frequentChanges')}>
              Company has experienced frequent or continuous changes in its business structure/Management.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('infoProblems')}>
              Problems obtaining the required information in the relevant form.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('suspiciousDocs')}>
              Unusual information or suspicious identification documents have been provided that cannot be readily verified.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('reluctantToProvideInfo')}>
              Reluctant to provide information needed to file reports or fails to proceed with transaction.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('none')}>None</Checkbox>

            <div style={{ marginTop: '16px', alignSelf: "flex-start" }}>
              <Checkbox checked={isEnhancedDD} onChange={handleEnhancedDDChange}>
                Enhanced DD applicable
              </Checkbox>

            </div>
            {isEnhancedDD && (
              <Input
                placeholder="Enhanced DD"
                value={enhancedDDValue}
                onChange={(e) => setEnhancedDDValue(e.target.value)}
                style={{ marginTop: '8px' }}
              />
            )}
          </div>


        </div>
      )

    },
    {
      title: "Geographical Risk Factors",
      content: (
        <div style={{ margin: '0 auto' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <h6 style={{ alignSelf: "flex-start" }}><i className='fa fa-list' style={{ marginRight: 10 }} />Select the applicable</h6>
            <Checkbox onChange={() => handleCheckboxChange('amlCftSystems')}>
              Countries without adequate Anti-Money Laundering/Counter Financing Terrorism systems as identified by credible sources.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('sanctionsEmbargoes')}>
              Countries under sanctions and embargoes or similar measures.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('corruptionCriminalActivity')}>
              Countries identified by credible sources as having significant levels of corruption or other criminal activity.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('terroristFinancing')}>
              Countries blacklisted for financing or supporting terrorist activities.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('terroristOrganisations')}>
              Locations that have designated terrorist organisations operating within their country.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('nonFATFMembers')}>
              Countries that are not members of the FATF and its partners.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('highRiskJurisdiction')}>
              Country or a territory that have dealings with high risk jurisdiction.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('none')}>None</Checkbox>

            <div style={{ marginTop: '16px', alignSelf: "flex-start" }}>
              <Checkbox checked={isEnhancedDD} onChange={handleEnhancedDDChange}>
                Enhanced DD applicable
              </Checkbox>

            </div>
            {isEnhancedDD && (
              <Input
                placeholder="Enhanced DD"
                value={enhancedDDValue}
                onChange={(e) => setEnhancedDDValue(e.target.value)}
                style={{ marginTop: '8px' }}
              />
            )}
          </div>
        </div>
      )
    },
    {
      title: "Transaction Risk Factors", content: (
        <div style={{ margin: '0 auto' }}>
          <div style={{ display: 'flex', flexDirection: 'column', gap: '20px' }}>
            <h6 style={{ alignSelf: "flex-start" }}><i className='fa fa-list' style={{ marginRight: 10 }} />Select the applicable</h6>
            <Checkbox onChange={() => handleCheckboxChange('amlCftSystems')}>
              The Customer set-up or purchase companies or business entities that have no obvious commercial purpose.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('sanctionsEmbargoes')}>
              The customer uses Private and correspondent banking.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('corruptionCriminalActivity')}>
              Unusual/Unclear source of Income.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('terroristFinancing')}>
              Non face to face business relationship or transactions.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('terroristOrganisations')}>
              Customer given any instruction to perform a transaction (which may include cash) anonymously.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('nonFATFMembers')}>
              The customer has transferred any funds without the provision of underlying services or transactions.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('highRiskJurisdiction')}>
              Large cash payments in which disbursement would have been normally made by other modes of payment like bank transfer, cheque, etc.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('unaccountedPayments')}>
              Unaccounted payments received from unknown or un-associated third parties for services and/or transactions provided by the customer/client.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('inconsistentTransactions')}>
              Transactions required by the customer is inconsistent with the professional intermediaries’ knowledge on the customer/client’s risk profile and nature of business.
            </Checkbox>
            <Checkbox onChange={() => handleCheckboxChange('none')}>None</Checkbox>

            <div style={{ marginTop: '16px', alignSelf: "flex-start" }}>
              <Checkbox checked={isEnhancedDD} onChange={handleEnhancedDDChange}>
                Enhanced DD applicable
              </Checkbox>
            </div>
            {isEnhancedDD && (
              <Input
                placeholder="Enhanced DD"
                value={enhancedDDValue}
                onChange={(e) => setEnhancedDDValue(e.target.value)}
                style={{ marginTop: '8px' }}
              />
            )}
          </div>
        </div>
      )
    },
    {
      title: "Rate the client Risk level and complexity", content: (
        <div style={{ justifyContent: "flex-start" }}>
          <div className='d-flex justify-content-between'>
            <Title level={5}>Based on the factors identified, rate the client risk level.</Title>
            <Row gutter={16}>
              <Col>
                <Checkbox>High</Checkbox>
              </Col>
              <Col>
                <Checkbox>Medium</Checkbox>
              </Col>
              <Col>
                <Checkbox>Low</Checkbox>
              </Col>
            </Row>
          </div>
          <div className='d-flex justify-content-between mt-3'>
            <Title level={5} style={{ marginTop: 20 }}>
              Based on the initial understanding, decide the nature of the client.
            </Title>
            <Row gutter={16}>
              <Col>
                <Checkbox>Complex</Checkbox>
              </Col>
              <Col>
                <Checkbox>Non-Complex</Checkbox>
              </Col>
            </Row>
          </div>
          <div className='d-flex flex-column justify-content-between mt-3'>
            <Title level={5} style={{ marginTop: 20, alignSelf: "flex-start" }}>Justification</Title>
            <TextArea rows={4} placeholder="Enter justification here" />
          </div>



          <div className='d-flex flex-column justify-content-between mt-3'>
            <Title level={5} style={{ marginTop: 20, alignSelf: "flex-start" }}>Attachments</Title>
            <UploadCard title="Constitution (MOA/AOA)" />

          </div>


        </div>
      )
    },
    { title: "Conclude on Acceptance", content:(
      <div style={{ justifyContent: "flex-start" }}>
      <div className='d-flex justify-content-between'>
        <Title level={5}>Conclusion</Title>
        <Row gutter={16}>
          <Col>
            <Checkbox>Recommended</Checkbox>
          </Col>
          <Col>
            <Checkbox>Not recommended</Checkbox>
          </Col>
        </Row>
      </div>
      <div className='d-flex flex-column justify-content-between mt-3'>
        <Title level={5} style={{ marginTop: 20, alignSelf: "flex-start" }}>Recommended by</Title>
        <Input placeholder="Enter justification here" />
        </div>
      <div className='d-flex flex-column justify-content-between mt-3'>
        <Title level={5} style={{ marginTop: 20, alignSelf: "flex-start" }}>Approved by</Title>
        <Input placeholder="Enter justification here" />
        </div>

    </div>
    ) }
  ];

  // Function to go to the next step
  const next = () => {
    setCurrent(prev => Math.min(prev + 1, steps.length - 1));
  };

  // Function to handle step click
  const onStepClick = (step) => {
    setCurrent(step);
  };

  return (
    <div style={{ width: '100%', margin: '20px auto', textAlign: 'center' }}>
      <Steps
        current={current}
        onChange={onStepClick}
        direction="horizontal"
        style={{ marginBottom: 24 }}
        labelPlacement="vertical"
      >
        {steps.map((step, index) => (
          <Step key={index} title={step.title} />
        ))}
      </Steps>

      {/* Content for each step */}
      <div style={{ marginTop: 24, padding: 20, borderRadius: 4 }}>
        {steps[current].content}
      </div>

      {/* Next button */}
      <div style={{ marginTop: 24 }}>
        <Button
          type="primary"
          onClick={next}
          // disabled={current === steps.length - 1}
        >
          {current === steps.length - 1 ? 'Finish' : 'Next'}
        </Button>
      </div>
    </div>
  );
};

export default StepperComponent;
