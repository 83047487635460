import React, { useEffect, useState } from 'react';
import { Upload, Card, Typography } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useParams } from 'react-router-dom';
import { errorNotification, successNotification } from '../../common/Alerts';
import axiosInstance from '../../apiIntecepter/axiosInstance';

const { Title } = Typography;

const UploadCard = ({ title, clientId ,UploadedPicture,setIsFetchAgain,isFetchAgain}) => {
  const [fileList, setFileList] = useState([]);
  const [uploading, setUploading] = useState(false); // Add state to track uploading status

  const handleChange = async ({ fileList }) => {
    setFileList(fileList);

    if (uploading) return; // Prevent multiple uploads

    setUploading(true); // Set uploading to true

    const formData = new FormData();
    fileList.forEach(file => {
      formData.append('confidential', file.originFileObj, file.name);
    });
    let token = localStorage.getItem('token');

    const myHeaders = new Headers();
    myHeaders.append("Authorization", `Bearer ${token}`);

    const requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: formData,
      redirect: "follow"
    };

    try {
      const response = await fetch(`https://auditpilot.cloudistic.net/api/Client/${clientId}/upload-confidential-documents`, requestOptions);
      const result = await response.text();
      console.log(result);
      successNotification("File uploaded successfully");
      setIsFetchAgain(!isFetchAgain)
      setFileList([])
    } catch (error) {
      errorNotification("Error uploading files");
      console.error('Error uploading files:', error);
    } finally {
      setUploading(false); // Reset uploading status
    }
  };

  return (
    <Card bordered={false} style={{ width: '70%', border: '1px dashed #d9d9d9', marginBottom: 20 }}>
      <Title level={5} style={{ color: '#219a9e' }}>{title}</Title>
      <Upload.Dragger
        style={{ padding: '40px 0' }}
        showUploadList={false}
        fileList={fileList}
        onChange={handleChange}
      >
        <PlusOutlined style={{ fontSize: 24 }} />
        <div>Upload Files</div>
      </Upload.Dragger>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: 20 }}>
        {fileList.map((file, index) => (
          <div key={index} style={{ margin: 10 }}>
            <img
              src={URL.createObjectURL(file.originFileObj)}
              alt="uploaded"
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
            <div>{file.name}</div>
          </div>
        ))}

        {
          console.log("UploadedPicture :::",UploadedPicture)
        }
            <div style={{ margin: 10 }}>
              <img
                src={`https://auditpilot.cloudistic.net${UploadedPicture}`}
                alt="uploaded"
                style={{ width: '150px', height: '150px', objectFit: 'cover' }}
              />
            </div>
      </div>
    </Card>
  );
};

const ConfidentialInformation = () => {
  const { clientId } = useParams();
  const [confidentialData, setConfidentialData] = useState([])
  const [isFetchAgain, setIsFetchAgain] = useState(false)
  useEffect(() => {
    const fetchConfidentialData = async () => {
      try {
        const confidentialNewData = await axiosInstance.get(`/Client/${clientId}/Confidential`);
        console.log(confidentialNewData?.data);
        setConfidentialData(confidentialNewData?.data?.confidentialInformationPath);
      } catch (error) {
        console.error("Error fetching beneficial owners data:", error);
      }
    };
    fetchConfidentialData();
  }, [isFetchAgain]);
  return (
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
      <UploadCard title="Confidential Information" clientId={clientId} UploadedPicture={confidentialData} setIsFetchAgain={setIsFetchAgain} isFetchAgain={isFetchAgain}/>
    </div>
  )
}

export default ConfidentialInformation;