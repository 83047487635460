import React, { useState } from 'react';
import { Upload, Card, Typography, Modal, Input } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { createStyles, useTheme } from 'antd-style';

const { Title } = Typography;

const UploadCard = ({ title }) => {
  const [fileList, setFileList] = useState([]);

  const handleChange = ({ fileList }) => setFileList(fileList);

  return (
    <Card bordered={false} style={{ width: '70%', border: '1px dashed #d9d9d9', marginBottom: 20 }}>
      <Title level={5} style={{ color: '#219a9e' }}>{title}</Title>
      <Upload.Dragger
        style={{ padding: '40px 0' }}
        showUploadList={false}
        fileList={fileList}
        onChange={handleChange}
        multiple
      >
        <PlusOutlined style={{ fontSize: 24 }} />
        <div>Upload Files</div>
      </Upload.Dragger>
      <div style={{ display: 'flex', flexWrap: 'wrap', justifyContent: 'center', marginTop: 20 }}>
        {fileList.map((file, index) => (
          <div key={index} style={{ margin: 10 }}>
            <img
              src={URL.createObjectURL(file.originFileObj)}
              alt="uploaded"
              style={{ width: '100px', height: '100px', objectFit: 'cover' }}
            />
            <div>{file.name}</div>
          </div>
        ))}
      </div>
    </Card>
  );
};
const useStyle = createStyles(({ token }) => ({
  'my-modal-body': {
    background: token.colorWhite,
    padding: token.paddingSM,
  },
  'my-modal-mask': {
    boxShadow: `inset 0 0 15px #fff`,
  },
  'my-modal-header': {
    borderBottom: `1px dotted ${token.colorPrimary}`,
  },
  'my-modal-footer': {
    color: token.colorPrimary,
  },
  'my-modal-content': {
    border: '1px solid #333',
  },
}));
const CustomImportantDocuments = () => {
  const [isModalOpen, setIsModalOpen] = useState([false, false]);
  const { styles } = useStyle();
  const token = useTheme();
  const [title, setTitle] = useState("")
  const [documents, setDocuments] = useState([]);



  const toggleModal = (index, isOpen) => {
    const newModalState = [...isModalOpen];
    newModalState[index] = isOpen;
    setIsModalOpen(newModalState);
  };

  const classNames = {
    body: styles['my-modal-body'],
    mask: styles['my-modal-mask'],
    header: styles['my-modal-header'],
    footer: styles['my-modal-footer'],
    content: styles['my-modal-content'],
  };

  const modalStyles = {
    header: {
      borderLeft: `5px solid ${token.colorPrimary}`,
      borderRadius: 0,
      paddingInlineStart: 5,
    },
    body: {
      borderRadius: 5,
    },
    mask: {},
    footer: {
      // borderTop: '1px solid #333',
    },
    content: {
      boxShadow: '0 0 30px #999',
    },
  };

  const handleOk = () => {
    setDocuments([...documents, { title }]);
    toggleModal(0, false);
    setTitle(''); // Reset title after adding
  };



  return (
    <>


      <div style={{ padding: 30, position: "relative", bottom: 20 }}>
        <div className='container' style={{ background: "white", borderRadius: 20, padding: 40, }} >
          <div className='mt-2 my-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
            <div className='d-flex'>
            </div>
            <div className='d-flex justify-content-between align-items-center gap-2 mx-3' onClick={() => {
              toggleModal(0, true);
            }}>
              <i className='fa fa-plus text-black mb-2 custom_add_icon' />
              <h6 className='custom_links'>Add New Document</h6>
            </div>
          </div>

          {documents.map((doc, index) => (
            <UploadCard key={index} title={doc.title} />
          ))}
        </div>
      </div>


      <Modal
        title="Add Document"
        open={isModalOpen[0]}
        onOk={handleOk}
        onCancel={() => toggleModal(0, false)}
        classNames={classNames}
        styles={modalStyles}
      >
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Title</label>
          <Input
            name="title"
            value={title}
            onChange={(e) => { setTitle(e.target.value) }}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
      </Modal>

    </>
  )
}

export default CustomImportantDocuments;
{/* <UploadCard title="Confidential Information" /> */ }