import React, { useState } from 'react';
import CustomProgessBar from './CustomProgessBar'; // Assuming you have this component

const CustomCard = ({ item }) => {
    const [isAccordionOpen, setIsAccordionOpen] = useState(false);

    const toggleAccordion = () => {
        setIsAccordionOpen(!isAccordionOpen);
    };

    return (
        <div>
            <div
                className='d-flex  w-100 my-2 px-3 py-2 card card-rounded '
                style={{ maxWidth: "95%", marginLeft: 20 ,boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',borderWidth:0 }}
                onClick={toggleAccordion}
            >

                <div className='w-100 d-flex justify-content-between align-items-center '>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        {
                            item?.isExpanded &&
                            <i className={`fa ${isAccordionOpen ? 'fa-chevron-up' : 'fa-chevron-down'} mb-2`}></i>
                        }

                        <h6 style={{ fontSize: 12, fontWeight: "bold", marginLeft: 5 }} className='text-secondary'>{item?.title}</h6>
                    </div>

                    <div style={{ width: "40%", display: "flex", justifyContent: "space-between" }}>
                        <CustomProgessBar
                            titleTop={`${item?.progressFirst.done}/${item?.progressFirst.total}`}
                            color={"bg-info"}
                            done={item?.progressFirst.done}
                            total={item?.progressFirst.total}
                        />
                        <CustomProgessBar
                            titleTop={`${item?.progressSecond.done}/${item?.progressSecond.total}`}
                            color={"bg-warning"}
                            done={item?.progressSecond.done}
                            total={item?.progressSecond.total}
                        />
                        <CustomProgessBar
                            titleTop={`${item?.progressThird.done}/${item?.progressThird.total}`}
                            color={"bg-success"}
                            done={item?.progressThird.done}
                            total={item?.progressThird.total}
                        />
                    </div>
                </div>
                <div>
                    {item?.isExpanded && isAccordionOpen && (
                        <div className='w-100 d-flex justify-content-between align-items-center mt-2 card flex-column p-3' style={{ boxShadow: '0 4px 18px rgba(0, 0, 0, 0.1)',borderWidth:0  }}>
                            {
                                item?.subCategories?.map((item, index) => (
                                    <div className='d-flex w-100 justify-content-between align-items-center my-2'>
                                        <div style={{ display: "flex", justifyContent: "center", alignItems: "center",}}>
                                            <h6 style={{ fontSize: 12, marginLeft: 5 }}  className='text-secondary'>{item?.title}</h6>
                                        </div>

                                        <div style={{ width: "40%", display: "flex", justifyContent: "space-between" }}>
                                            {
                                                item?.progressFirst ? <i className="fa fa-check text-info mx-5"></i> : <i className="fa fa-check text-secondary mx-5"></i>
                                            }
                                            {
                                                item?.progressSecond ? <i className="fa fa-check text-info mx-5"></i> : <i className="fa fa-check text-secondary mx-5"></i>
                                            }
                                            {
                                                item?.progressThird ? <i className="fa fa-check text-info mx-5"></i> : <i className="fa fa-check text-secondary mx-5"></i>
                                            }

                                        </div>
                                    </div>
                                ))
                            }

                        </div>
                    )}
                </div>

            </div>

        </div>
    );
};

export default CustomCard;