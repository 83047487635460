// utils/axiosInstance.js
import axios from 'axios';

// Configure the base URL
const API_BASE_URL = 'https://auditpilot.cloudistic.net/api'; // replace with your API base URL

// Create an Axios instance
const axiosInstance = axios.create({
  baseURL: API_BASE_URL,
  headers: {
    'Content-Type': 'application/json', // Optional, depending on your API's requirements
  },
});

// Add a request interceptor to dynamically add the token from localStorage
axiosInstance.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Get the token from localStorage
    if (token) {
      config.headers.Authorization = `Bearer ${token}`;
    }
    return config;
  },
  (error) => Promise.reject(error)
);

export default axiosInstance;