import React, { useEffect, useState } from 'react';
import { createStyles, useTheme } from 'antd-style';
import { Avatar, Button, Dropdown, Input, Menu, Modal, Checkbox } from 'antd';
import { DatePicker } from 'antd';
import { errorNotification, successNotification } from '../common/Alerts';
import TableArea from './TableArea';
import moment from 'moment';
import { UserOutlined } from '@ant-design/icons';
import { Link } from 'react-router-dom';
const useStyle = createStyles(({ token }) => ({
    'my-modal-body': {
        background: token.colorWhite,
        padding: token.paddingSM,
    },
    'my-modal-mask': {
        boxShadow: `inset 0 0 15px #fff`,
    },
    'my-modal-header': {
        borderBottom: `1px dotted ${token.colorPrimary}`,
    },
    'my-modal-footer': {
        color: token.colorPrimary,
    },
    'my-modal-content': {
        border: '1px solid #333',
    },
}));

const ClientsArea = () => {
    const { MonthPicker } = DatePicker;

    const [isModalOpen, setIsModalOpen] = useState([false, false]);
    const { styles } = useStyle();
    const token = useTheme();

    const [clientName, setClientName] = useState('');
    const [fye, setFye] = useState(null);
    const [clientContact, setClientContact] = useState('');
    const [phone, setPhone] = useState('');
    const [email, setEmail] = useState('');
    const [remarks, setRemarks] = useState('');
    const [error, setError] = useState('');
    const [selectedColumns, setSelectedColumns] = useState([]);

    useEffect(() => {
        const savedColumns = JSON.parse(localStorage.getItem('selectedColumns')) || ["id", "clientName", "fye", "clientContact", "email"];
        setSelectedColumns(savedColumns);
    }, []);
    const toggleModal = (idx, target) => {
        setIsModalOpen((p) => {
            p[idx] = target;
            return [...p];
        });
    };

    const fetchClientsData = async () => {
        let yourToken = localStorage.getItem("token");
        const options = {
            method: "GET",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${yourToken}`,
            },
        };
       
        fetch("https://auditpilot.cloudistic.net/api/Client/GetAll", options)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                const formattedData = data?.map((item, index) => ({
                    key: index,
                    id: item?.id ?? "-",
                    clientName: item?.clientName ?? "-",
                    clientContact: item?.clientContact ?? "-",
                    email: item?.email ?? "-",
                    phone: item?.phone ?? "-",
                    fye: item?.fye ? moment(item.fye).format('DD MMMM') : "-",
                    remarks: item?.remarks ?? "-",
                    entityType: item?.entityType ?? "-",
                    industry: item?.industry ?? "-",
                    parentCompany: item?.parentCompany ?? "-",
                    licensingAuthority: item?.licensingAuthority ?? "-",
                    registrationNumber: item?.registrationNumber ?? "-",
                    licenseNumber: item?.licenseNumber ?? "-",
                    taxRegistrationNumber: item?.taxRegistrationNumber ?? "-",
                    dateOfIncorporation: item?.dateOfIncorporation ?? "-",
                    countryOfIncorporation: item?.countryOfIncorporation ?? "-",
                    cityOfIncorporation: item?.cityOfIncorporation ?? "-",
                    principalPlaceOfBusiness: item?.principalPlaceOfBusiness ?? "-",
                    registeredAddress: item?.registeredAddress ?? "-",
                    postalAddress: item?.postalAddress ?? "-",
                    reportingCurrency: item?.reportingCurrency ?? "-",
                    businessDescription: item?.businessDescription ?? "-",
                    auditingStandards: item?.auditingStandards ?? "-",
                    accountingStandards: item?.accountingStandards ?? "-",
                    companyAct: item?.companyAct ?? "-",
                    regulations: item?.regulations ?? "-",
                    createdBy: item?.createdBy ?? "-",
                    createdAt: moment(item?.createdAt).format('DD MMMM') ?? "-",
                    updatedBy: item?.updatedBy ?? "-",
                    updatedAt: item?.updatedAt ?? "-",
                    isActive: item?.isActive ?? "-",
                }));
                console.log("data ", data[0])
                setClientsData(formattedData);
            })
            .catch((error) => {
                console.log("error", error);
                // Handle error response
            });
    };

    const handleOk = () => {
        console.log({
            clientName,
            fye,
            clientContact,
            phone,
            email,
            remarks,
        });
        let yourToken = localStorage.getItem("token");
        const options = {
            method: "POST",
            headers: {
                Accept: "application/json",
                "Content-Type": "application/json;charset=UTF-8",
                Authorization: `Bearer ${yourToken}`, // Add your token here
            },

            body: JSON.stringify({
                clientName: clientName,
                fye: fye,
                clientContact: clientContact,
                phone: phone,
                email: email,
                remarks: remarks
            }),
        };

        fetch("https://auditpilot.cloudistic.net/api/Client", options)
            .then((response) => {
                if (!response.ok) {
                    throw new Error('Network response was not ok ' + response.statusText);
                }
                return response.json();
            })
            .then((data) => {
                console.log("data", data)

                successNotification("Client Created Successfully!");
                // fetchClientsData(); // Ensure this is called after success

                toggleModal(0, false);

                setClientName('');
                setFye(null);
                setClientContact('');
                setPhone('');
                setEmail('');
                setRemarks('');
                setError('');

                window.location.reload();
                // Handle success response
            })
            .catch((error) => {
                console.log("error", error);
                errorNotification("Please Enter Valid Information")
                toggleModal(0, false);
                // Handle error response
            });
    };

    const classNames = {
        body: styles['my-modal-body'],
        mask: styles['my-modal-mask'],
        header: styles['my-modal-header'],
        footer: styles['my-modal-footer'],
        content: styles['my-modal-content'],
    };

    const modalStyles = {
        header: {
            borderLeft: `5px solid ${token.colorPrimary}`,
            borderRadius: 0,
            paddingInlineStart: 5,
        },
        body: {
            borderRadius: 5,
        },
        mask: {},
        footer: {
            // borderTop: '1px solid #333',
        },
        content: {
            boxShadow: '0 0 30px #999',
        },
    };

    const validateEmail = (email) => {
        const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return re.test(String(email).toLowerCase());
    };

    const handleChange = (e) => {
        const value = e.target.value;
        setEmail(value);
        if (!validateEmail(value)) {
            setError('Please enter a valid email');
        } else {
            setError('');
        }
    };

    const [clientsData, setClientsData] = useState([]);
    useEffect(() => {
        const dropdown = document.getElementById('dropdown-id'); // Replace with your dropdown's actual ID

        const handleParentClick = (e) => {
            e.stopPropagation();
        };

        if (dropdown) {
            dropdown.addEventListener('click', handleParentClick);
        }

        return () => {
            if (dropdown) {
                dropdown.removeEventListener('click', handleParentClick);
            }
        };
    }, []);
    useEffect(() => {
        fetchClientsData();
    }, [clientName, remarks, email, phone]);

    // const handleCheckboxClick = (e) => {
    //     e.stopPropagation();
    // };

    const handleCheckboxClick = (e, key) => {
        e.stopPropagation();
        e.preventDefault(); // Add this line to prevent default action if necessary

        const updatedColumns = selectedColumns.includes(key)
            ? selectedColumns.filter(column => column !== key)
            : [...selectedColumns, key];

        setSelectedColumns(updatedColumns);
        localStorage.setItem('selectedColumns', JSON.stringify(updatedColumns));
    };

    const columns = [
        {
            title: <span><i className="fa fa-id-badge px-2"></i> Client Id</span>,
            dataIndex: 'id',
            key: 'id',
        },
        {
            title: 'Client Name',
            dataIndex: 'clientName',
            key: 'clientName',
            render: (text, record) => (
                <span>
                    <Link style={{color:"#219a9e",fontWeight:"bold"}} to={`/new-client/${record.id}`}>
                        {text}
                    </Link>
                </span>
            ),
        },
        {
            title: <span><i className="fa fa-user"></i> FYE</span>,
            dataIndex: 'fye',
            key: 'fye',
        },
        {
            title: <span><i className="fa fa-address-book"></i> Client Contact</span>,
            dataIndex: 'clientContact',
            key: 'clientContact',
        },
        {
            title: <span><i className="fa fa-envelope"></i> Email</span>,
            dataIndex: 'email',
            key: 'email',
        },
        {
            title: <span><i className="fa fa-phone"></i> Phone</span>,
            dataIndex: 'phone',
            key: 'phone',
        },
        {
            title: 'Remarks',
            dataIndex: 'remarks',
            key: 'remarks',
        },
        {
            title: 'Entity Type',
            dataIndex: 'entityType',
            key: 'entityType',
        },
        {
            title: 'Industry',
            dataIndex: 'industry',
            key: 'industry',
        },
        {
            title: 'Parent Company',
            dataIndex: 'parentCompany',
            key: 'parentCompany',
        },
        {
            title: 'Licensing Authority',
            dataIndex: 'licensingAuthority',
            key: 'licensingAuthority',
        },
        {
            title: 'Registration Number',
            dataIndex: 'registrationNumber',
            key: 'registrationNumber',
        },
        {
            title: 'License Number',
            dataIndex: 'licenseNumber',
            key: 'licenseNumber',
        },
        {
            title: 'Tax Registration Number',
            dataIndex: 'taxRegistrationNumber',
            key: 'taxRegistrationNumber',
        },
        {
            title: 'Date of Incorporation',
            dataIndex: 'dateOfIncorporation',
            key: 'dateOfIncorporation',
        },
        {
            title: 'Country of Incorporation',
            dataIndex: 'countryOfIncorporation',
            key: 'countryOfIncorporation',
        },
        {
            title: 'City of Incorporation',
            dataIndex: 'cityOfIncorporation',
            key: 'cityOfIncorporation',
        },
        {
            title: 'Principal Place of Business',
            dataIndex: 'principalPlaceOfBusiness',
            key: 'principalPlaceOfBusiness',
        },
        {
            title: 'Registered Address',
            dataIndex: 'registeredAddress',
            key: 'registeredAddress',
        },
        {
            title: 'Postal Address',
            dataIndex: 'postalAddress',
            key: 'postalAddress',
        },
        {
            title: 'Reporting Currency',
            dataIndex: 'reportingCurrency',
            key: 'reportingCurrency',
        },
        {
            title: 'Business Description',
            dataIndex: 'businessDescription',
            key: 'businessDescription',
        },
        {
            title: 'Auditing Standards',
            dataIndex: 'auditingStandards',
            key: 'auditingStandards',
        },
        {
            title: 'Accounting Standards',
            dataIndex: 'accountingStandards',
            key: 'accountingStandards',
        },
        {
            title: 'Company Act',
            dataIndex: 'companyAct',
            key: 'companyAct',
        },
        {
            title: 'Regulations',
            dataIndex: 'regulations',
            key: 'regulations',
        },
        {
            title: 'Created By',
            dataIndex: 'createdBy',
            key: 'createdBy',
        },
        {
            title: 'Created At',
            dataIndex: 'createdAt',
            key: 'createdAt',
        },
        {
            title: 'Updated By',
            dataIndex: 'updatedBy',
            key: 'updatedBy',
        },
        {
            title: 'Updated At',
            dataIndex: 'updatedAt',
            key: 'updatedAt',
        },
        {
            title: 'Is Active',
            dataIndex: 'isActive',
            key: 'isActive',
        },
    ];

    const filteredColumns = columns.filter(column => selectedColumns.includes(column.key));

    const profileItems = (
        <Menu id="dropdown-id">
            {columns?.map(column => (
                <Menu.Item key={column.key}>
                    <Checkbox
                        style={{ marginRight: '8px' }}
                        onChange={(e) => handleCheckboxClick(e, column.key)}
                        checked={selectedColumns.includes(column.key)}
                    />
                    {column.title}
                </Menu.Item>
            ))}
        </Menu>
    );

    return (
        <>
            <div className="container-fluid card ">
                <div className="card-body">
                    <div className='d-flex justify-content-between'>
                        <div className="text-right">
                            <i className="fas fa-user"></i> Clients
                        </div>
                        <div style={{}}>
                            <button type="button" className="btn btn-success btn-sm mx-2 btn-add-client fw-bold" onClick={() => toggleModal(0, true)}>
                                <i className="fas fa-plus"></i> Add client
                            </button>

                            <Dropdown overlay={profileItems} trigger={['click']}>
                                <Button type="button" className="btn btn-outline-secondary btn-sm mx-2 btn-clients">
                                    <i className="fas fa-filter mx-1"></i>
                                    <i className="fas fa-caret-down mx-2"></i>
                                </Button>
                            </Dropdown>
                            <button type="button" className="btn btn-outline-secondary btn-sm mx-2 btn-clients fw-bold">
                                <i className="fas fa-cloud-download-alt"></i> Download
                            </button>
                            <button type="button" className="btn btn-outline-secondary btn-sm mx-2 btn-clients fw-bold">
                                <i className="fas fa-cloud-upload-alt"></i> Bulk Upload
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <TableArea clientsData={clientsData} filteredColumns={filteredColumns} />

            <Modal
                title="Add client"
                open={isModalOpen[0]}
                onOk={handleOk}
                onCancel={() => toggleModal(0, false)}
                classNames={classNames}
                styles={modalStyles}
            >
                <label className='mt-2'>Client Name</label>
                <Input value={clientName} onChange={(e) => setClientName(e.target.value)} />
                <label className='mt-2'>FYE</label>
                <br />
                <MonthPicker placeholder="Select Month" style={{ width: "100%" }} value={fye} onChange={(date) => setFye(date)} />
                <label className='mt-2'>Client Contact</label>
                <Input value={clientContact} onChange={(e) => setClientContact(e.target.value)} />
                <label className='mt-2'>Phone</label>
                <Input value={phone} onChange={(e) => setPhone(e.target.value)} />
                <div>
                    <label className='mt-2'>Email</label>
                    <Input value={email} onChange={handleChange} />
                    {error && <p style={{ color: 'red' }}>{error}</p>}
                </div>
                <label className='mt-2'>Remarks</label>
                <Input value={remarks} onChange={(e) => setRemarks(e.target.value)} />
            </Modal>
        </>
    );
}

export default ClientsArea;