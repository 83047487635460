import React, { useState } from 'react';
import { Layout, Row, Col, Button, Space, Typography, Alert, Tabs, Menu, Avatar } from 'antd';
import {
    BellFilled,
    BellOutlined,
    DeleteFilled,
    FolderFilled,
    ProfileOutlined,
    UserOutlined,
    UserSwitchOutlined,
} from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router-dom';
import { DownOutlined } from '@ant-design/icons';
import { Dropdown } from 'antd';


const { Header } = Layout;
const { Title } = Typography;


const CustomHeader = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const buttonColor = location.pathname === '/clients' ? 'text-warning' : 'text-white';
    const onChange = (key) => {
        console.log(key);
    };

    const handleSignOut = () => {
        localStorage.clear(); // Remove all items from local storage
        navigate('/login'); // Navigate to the login page
      };
      
      const profileItems = (
        <Menu style={{ width: 250, position: "relative", top: 10 }}>
            <Menu.Item key="1">
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <Avatar style={{ backgroundColor: '#219a9e', marginRight: 10 }}>A</Avatar>
                    <div>
                        <div style={{ fontWeight: 'bold' }}>John Doe</div>
                        <div style={{ fontSize: '12px', color: 'gray' }}>@johndoe</div>
                    </div>
                </div>
            </Menu.Item>
            <Menu.Item key="2" onClick={()=>{navigate("/firm-details")}}>Firm Details</Menu.Item>
            {/* <Menu.Item key="3">Security</Menu.Item>
            <Menu.Item key="4">Billing & Subscriptions</Menu.Item> */}
            <Menu.Item key="5" onClick={handleSignOut}>Sign out</Menu.Item>
        </Menu>
    );

  return (
    <Layout >
    <Header style={{ backgroundColor: '#219a9e', padding: '0 20px', width: '100%', zIndex: 1 }}>
        <Row justify="space-between" align="middle">
            {/* Left Side - Logo */}
            <Col onClick={() => { navigate("/dashboard") }} style={{ cursor: "pointer" }}>
                <Title level={4} style={{ color: 'white', margin: 0 }}>
                    Audit Pilot
                </Title>
            </Col>

            {/* Right Side - Icons */}
            <Col>

                <Button onClick={()=>{navigate("/dashboard")}} style={{ background: "transparent", borderWidth: 0, flexDirection: "column", position: "relative", bottom: 10 }}>
                    <i className="fas fa-folder dashboard-menu-icon text-white fa-lg mb-1"></i>
                    <span style={{ color: 'white',fontSize:12 }}>Jobs</span>
                </Button>
                <Button style={{ background: "transparent", borderWidth: 0, flexDirection: "column", position: "relative", bottom: 10 }} onClick={() => {
                    navigate("/clients")
                }}>
                    <i className={`fas fa-user dashboard-menu-icon ${buttonColor} fa-lg mb-1`}></i>
                    <span className={buttonColor} style={{fontSize:12}}>Clients</span>
                </Button>
                <Button style={{ background: "transparent", borderWidth: 0, flexDirection: "column", position: "relative", bottom: 10 }}>
                    <i className="fas fa-users dashboard-menu-icon text-white fa-lg mb-1"></i>
                    <span style={{ color: 'white' ,fontSize:12}}>Staff</span>
                </Button>
                <Button style={{ background: "transparent", borderWidth: 0, flexDirection: "column", position: "relative", bottom: 10 }}>
                    <i className="fas fa-list dashboard-menu-icon text-white fa-lg mb-1"></i>
                    <span style={{ color: 'white',fontSize:12 }}>Library</span>
                </Button>
                <Button style={{ background: "transparent", borderWidth: 0, flexDirection: "column", position: "relative", bottom: 10 }}>
                    <i className="fas fa-trash dashboard-menu-icon text-white fa-lg mb-1"></i>
                    <span style={{ color: 'white',fontSize:12 }}>Recycle Bin</span>
                </Button>
                <Button style={{ background: "transparent", borderWidth: 0, flexDirection: "column", position: "relative", bottom: 10 }}>
                    <i className="fas fa-bell dashboard-menu-icon text-white fa-lg mb-1"></i>
                    <span style={{ color: 'white' ,fontSize:12}}>Notifications</span>
                </Button>
                <Dropdown overlay={profileItems} trigger={['click']}>
                    <Button style={{ background: "transparent", borderWidth: 0, flexDirection: "column", position: "relative", bottom: 10 }}>
                        <i className="fas fa-user-circle dashboard-menu-icon text-white fa-lg mb-1"></i>
                        <span style={{ color: 'white' ,fontSize:12}}>Profile</span>
                    </Button>
                </Dropdown>
            </Col>
        </Row>
    </Header>
</Layout>
  )
}

export default CustomHeader