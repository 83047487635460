import React, { useEffect, useState } from 'react'
import TableArea from '../../components/TableArea';
import { Button, Checkbox, Menu, message, Table, Upload } from 'antd';
import { Form, Input, Select, DatePicker, Row, Col, Modal } from 'antd';
import { KYCTableColumns, sampleClientsData } from '../../utils/clientsUtils';
import StepperComponent from '../../components/StepperComponent';
import { createStyles, useTheme } from 'antd-style';
import { Link, useParams } from 'react-router-dom';
import Dragger from 'antd/es/upload/Dragger';
import axiosInstance from '../../apiIntecepter/axiosInstance';
import moment from 'moment';


const { Option } = Select;

const useStyle = createStyles(({ token }) => ({
  'my-modal-body': {
    background: token.colorWhite,
    padding: token.paddingSM,
  },
  'my-modal-mask': {
    boxShadow: `inset 0 0 15px #fff`,
  },
  'my-modal-header': {
    borderBottom: `1px dotted ${token.colorPrimary}`,
  },
  'my-modal-footer': {
    color: token.colorPrimary,
  },
  'my-modal-content': {
    border: '1px solid #333',
  },
}));
const CddAndKyc = () => {
  const { clientId } = useParams();
  const [kycData, setKycData] = useState([])
  const { styles } = useStyle();
  const token = useTheme();
  const [isModalOpen, setIsModalOpen] = useState([false, false]);
  const [isEditing, setIsEditing] = useState(false);
  const [currentClient, setCurrentClient] = useState(null);
  const { confirm } = Modal;
  const [formData, setFormData] = useState({
    id:'',
    name: '',
    positionHeld: '',
    sNo: '',
    screeningOn: null,
    sanctionsList: false,
    pep: false,
    observations: '',
    outputs: ''
  });


  const toggleModal = (index, isOpen, client = null) => {
    const newModalState = [...isModalOpen];
    newModalState[index] = isOpen;
    setIsModalOpen(newModalState);
    setIsEditing(!!client);
    setCurrentClient(client);
  };





  const classNames = {
    body: styles['my-modal-body'],
    mask: styles['my-modal-mask'],
    header: styles['my-modal-header'],
    footer: styles['my-modal-footer'],
    content: styles['my-modal-content'],
  };

  const modalStyles = {
    header: {
      borderLeft: `5px solid ${token.colorPrimary}`,
      borderRadius: 0,
      paddingInlineStart: 5,
    },
    body: {
      borderRadius: 5,
    },
    mask: {},
    footer: {
      // borderTop: '1px solid #333',
    },
    content: {
      boxShadow: '0 0 30px #999',
    },
  };


  const handleOk = () => {
    let body = {
      "name": formData.name,
      "positionHeld": formData.positionHeld,
      "sNo": 0,
      "screeningOn": formData.screeningOn,
      "sanctionsList": formData.sanctionsList,
      "pep": formData?.pep,
      "observations": formData.observations,
      "output": formData.outputs
    }
    axiosInstance.post(`/client/${clientId}/clientkyc`, body).then((response) => {
      setKycData([...kycData, response?.data])
      toggleModal(0, false)
    }).catch((error) => { console.log("err", error) })
  };
  const handleEdit = () => {
    let body = {
      "name": formData.name,
      "positionHeld": formData.positionHeld,
      "sNo": 0,
      "screeningOn": formData.screeningOn,
      "sanctionsList": formData.sanctionsList,
      "pep": formData?.pep,
      "observations": formData.observations,
      "output": formData.outputs ? formData.outputs : "none"
    }
    axiosInstance.put(`/client/${clientId}/clientkyc/${formData?.id}`, body).then((response) => {
      setKycData(kycData?.map(note => 
        note?.id === response?.data?.id ? response?.data : note
      ));
      toggleModal(0, false)
    }).catch((error) => { console.log("err", error) })
  };

  const showDeleteConfirm = (isKyc,record) => {
    confirm({
      title: 'Remove User',
      content: `Are you sure you want to delete kyc?`,
      okText: 'Yes',
      okType: 'danger',
      cancelText: 'No',
      onOk() {
        axiosInstance.delete(`/client/${clientId}/clientkyc/${record.id}`).then((response) => {
          setKycData(kycData.filter((kyc) => kyc.id !== record.id))
        }).catch((error) => { console.log("err", error) })
      },
      onCancel() {
        console.log('Cancel');
      },
    });
  };

  const handleEditClick = (record, isNote) => {
    setFormData({
      id: record?.id,
      sNo: record?.sNo,
      name: record.name,
      positionHeld: record.positionHeld,
      screeningOn: record?.screeningOn ? moment(record.screeningOn) : null,
      outputs: record.outputs,
      observations: record.observations,
      pep: !!record.pep,
      sanctionsList: !!record.sanctionsList,
    });
    toggleModal(0, true, record);
  };

  const [expandedRowKeys, setExpandedRowKeys] = useState([]);

  const handleExpandClick = (recordId) => {
    setExpandedRowKeys((prevKeys) => {
      if (prevKeys.includes(recordId)) {
        // If the row is already expanded, remove it from the keys (collapse)
        return prevKeys.filter(key => key !== recordId);
      } else {
        // If the row is not expanded, collapse all others and expand this one
        return [recordId];
      }
    });
  };

  const KYCTableColumns = [
    {
      title: 'S No',
      dataIndex: 'sNo',
      key: 'sNo',
    },
    {
      title: 'Name',
      dataIndex: 'name',
      key: 'name',
      render: (text, record) => {
        return (
          <div>
            <span>
              {/* <i className="fa-solid fa-chevron-down mx-2"></i> */}
              <span to={`/new-client/${record.id}`}>
                {record.name}
              </span>
            </span>
          </div>
        )
      }
    },
    {
      title: 'Position Held',
      dataIndex: 'positionHeld',
      key: 'positionHeld',
    },
    {
      title: 'Date of Screening',
      dataIndex: 'screeningOn',
      key: 'screeningOn',
      render: (text) => moment(text).format('YYYY-MM-DD'),
    },
    {
      title: 'Output',
      dataIndex: 'output',
      key: 'output',
    },
    {
      title: 'Observations',
      dataIndex: 'observations',
      key: 'observations',
    },
    {
      title: 'PEP',
      dataIndex: 'pep',
      key: 'pep',
      render: (text, record) => (
        <i className={record.pep ? 'fa fa-check text-user' : 'fa fa-xmark text-black'}></i>

      ),
    },
    {
      title: 'Sanctions List',
      dataIndex: 'sanctionsList',
      key: 'sanctionsList',
      render: (text, record) => {
        return (
          <i className={record.sanctionsList ? 'fa fa-check text-user' : 'fa fa-xmark text-black'}></i>
        );
      },
    },
    {
      title: 'Action',
      dataIndex: 'action',
      key: 'action',
      render: (text, record) => (
        <span>

          <i className='fa fa-edit text-primary mx-3' onClick={() => handleEditClick(record, false)} />
          <i className='fa fa-trash text-danger' onClick={() => { showDeleteConfirm(false,record) }} />
        </span>
      ),
    },
  ];

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleDateChange = (date) => {
    const validDate = moment(date);
    if (validDate.isValid()) {
      // Update the formData with the valid date
      setFormData({ ...formData, screeningOn: validDate });
    } else {
      // Handle invalid date
      console.error('Invalid date');
    }
  };
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setFormData({
      ...formData,
      [name]: checked
    });
  };
  const expandedRowRender = (record) => {
    const { Dragger } = Upload;
    const props = {
      name: 'file',
      action: 'https://www.mocky.io/v2/5cc8019d300000980a055e77',
      onChange(info) {
        const { status } = info.file;
        if (status === 'done') {
          message.success(`${info.file.name} file uploaded successfully.`);
        } else if (status === 'error') {
          message.error(`${info.file.name} file upload failed.`);
        }
      },
      onDrop(e) {
        // console.log('Dropped files:', e.dataTransfer.files);
      },
    };

    return (
      <tr>
        <td colSpan="8">
          <Dragger {...props} style={{ width: 600 }}>
            <p className="ant-upload-drag-icon">
              <i className='fa fa-plus' />
            </p>
            <p className="ant-upload-text">Upload Media</p>
            {/* <p className="ant-upload-hint">
              Support for a single or bulk upload. Strictly prohibit from uploading company data or other
              sensitive information.
            </p> */}
          </Dragger>
        </td>
      </tr>
    );
  };



  const sampleClientsData = [
    {
      sNo: 1,
      name: 'John Doe',
      positionHeld: 'Manager',
      screeningOn: '2023-01-15',
      output: 'Clear',
      observations: 'None',
      pep: false,
      sanctionsList: true,
    },
    {
      sNo: 2,
      name: 'Jane Smith',
      positionHeld: 'Director',
      screeningOn: '2023-02-20',
      output: 'Pending',
      observations: 'Requires further review',
      pep: 'Yes',
      pep: true,
      sanctionsList: false,
    },
  ];

  useEffect(() => {
    const fetchData = async () => {
      try {
        const [clientkycResponse, notesResponse] = await Promise.all([
          axiosInstance.get(`/client/${clientId}/clientkyc`),
          // axiosInstance.get(`/Client/${clientId}/notes`)
        ]);
        setKycData(clientkycResponse?.data)
      } catch (error) {
        console.error("Error:", error);
      }
    };

    fetchData();
  }, []);

  return (
    <>
      <div style={{ backgroundColor: "#eeeeee", padding: 30, position: "relative", bottom: 10 }}>
        <div className='container' style={{ background: "white", borderRadius: 20, padding: 40, }} >
          <div className='py-0' style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", marginBottom: 20 }}>
            <div style={{ height: 30, width: 30, borderRadius: 100, background: "orange", display: "flex", justifyContent: "center", alignItems: "center" }}><svg id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" className="styles_headerImg__2uJCE"><linearGradient id="PUZZLE_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stopColor="#c3ffe8"></stop><stop offset="0.9973" stopColor="#f0fff4"></stop></linearGradient><linearGradient id="PUZZLE_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="407" y2="105"><stop offset="0" stopColor="#9cffac"></stop><stop offset="1" stopColor="#00b59c"></stop></linearGradient><g><g><circle cx="256" cy="256" fill="url(#PUZZLE_1_)" r="256"></circle></g><g><g id="Puzzle_2_"><g><path d="m256 407c-19.579 0-35.5-15.934-35.5-35.529 0-4.432.832-10.765 2.279-17.765h-64.404c-4.906 0-8.875-3.973-8.875-8.882v-66.869c0-2.776 1.291-5.387 3.501-7.069 2.21-1.665 5.07-2.221 7.74-1.492 10.834 3.001 20.168 4.372 24.259 4.372 9.785 0 17.75-7.972 17.75-17.765s-7.965-17.765-17.75-17.765c-4.091 0-13.425 1.371-24.259 4.372-2.687.737-5.538.191-7.74-1.492-2.21-1.683-3.501-4.294-3.501-7.069v-66.869c0-4.91 3.969-8.882 8.875-8.882h64.404c-1.447-7-2.279-13.332-2.279-17.765 0-19.597 15.921-35.531 35.5-35.531s35.5 15.934 35.5 35.529c0 4.432-.832 10.765-2.279 17.765h64.404c4.906 0 8.875 3.973 8.875 8.882v66.869c0 2.776-1.291 5.387-3.501 7.069-2.184 1.683-5.044 2.238-7.74 1.492-10.834-3.001-20.168-4.372-24.259-4.372-9.785 0-17.75 7.972-17.75 17.765s7.965 17.765 17.75 17.765c4.091 0 13.425-1.371 24.259-4.372 2.687-.746 5.538-.182 7.74 1.492 2.21 1.683 3.501 4.294 3.501 7.069v66.869c0 4.91-3.969 8.882-8.875 8.882h-64.404c1.447 7 2.279 13.332 2.279 17.765 0 19.597-15.921 35.531-35.5 35.531z" fill="url(#PUZZLE_2_)"></path></g></g></g></g></svg></div>
            <h6 className='mt-2 mx-2 fw-bold'>KYC Screening</h6>
          </div>




          <Table
            // dataSource={sampleClientsData}
            dataSource={kycData}
            columns={KYCTableColumns}
            scroll={{ x: 'max-content' }}
            expandedRowRender={expandedRowRender}
            expandedRowKeys={expandedRowKeys}
            rowKey="sNo" // Ensure your data has a unique key
            onExpand={(expanded, record) => {
              handleExpandClick(record.sNo); // This will toggle the expanded state
            }}
          // pagination={false}
          />




          <div className='mt-2 my-3' style={{ display: "flex", justifyContent: "space-between", alignItems: "center", }}>
            <div className='d-flex'>
            </div>
            <div className='d-flex justify-content-between align-items-center gap-2 mx-3' onClick={() => {
              setFormData({
                id:"",
                sNo: "",
                name: "",
                positionHeld: "",
                screeningOn: "",
                output: "",
                observations: "",
                pep: false,
                sanctionsList: false,
              })
              toggleModal(0, true)
            }}>
              <i className='fa fa-plus text-black mb-2 custom_add_icon' />
              <h6 className='custom_links'>Add KYC Screening</h6>
            </div>
          </div>


          {/* Client Due Diligence */}
          <div onClick={() => toggleModal(0, true)} className='mt-2 mb-3' style={{ display: "flex", justifyContent: "flex-start", alignItems: "center", }}>
            <div style={{ height: 30, width: 30, borderRadius: 100, background: "orange", display: "flex", justifyContent: "center", alignItems: "center" }}><svg id="Capa_1" enableBackground="new 0 0 512 512" height="512" viewBox="0 0 512 512" width="512" className="styles_headerImg__79s_t"><linearGradient id="PIN_1_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="512" y2="0"><stop offset="0" stopColor="#ffc8d1"></stop><stop offset="1" stopColor="#fff6f7"></stop></linearGradient><linearGradient id="PIN_2_" gradientUnits="userSpaceOnUse" x1="256" x2="256" y1="105.027" y2="407"><stop offset="0" stopColor="#fd3a84"></stop><stop offset="1" stopColor="#ffa68d"></stop></linearGradient><g><g><circle cx="256" cy="256" fill="url(#PIN_1_)" r="256"></circle></g><g><g><path d="m247.118 282.658h-35.526v-133.223c0-4.909-3.972-8.882-8.882-8.882s-8.882 3.973-8.882 8.882v133.223h-35.526c-4.905 0-8.882 3.976-8.882 8.881 0 21.483 15.256 39.397 35.526 43.512v36.422c0 4.905-3.977 8.882-8.882 8.882s-8.881 3.976-8.881 8.881v8.881c0 4.905 3.977 8.882 8.881 8.882h53.289c4.905 0 8.881-3.977 8.881-8.882v-8.881c0-4.906-3.977-8.881-8.881-8.881-4.905 0-8.882-3.977-8.882-8.882v-36.422c20.273-4.114 35.529-22.029 35.529-43.511 0-4.906-3.977-8.882-8.882-8.882zm79.934-105.683v-36.422c0-4.906 3.977-8.881 8.882-8.881s8.881-3.976 8.881-8.882v-8.882c0-4.905-3.977-8.882-8.881-8.882h-53.289c-4.905 0-8.881 3.977-8.881 8.882v8.882c0 4.906 3.977 8.882 8.881 8.882 4.905 0 8.882 3.976 8.882 8.881v36.422c-20.271 4.116-35.527 22.029-35.527 43.512 0 4.906 3.977 8.882 8.882 8.882h35.526v133.223c0 4.909 3.972 8.881 8.882 8.881s8.882-3.972 8.882-8.881v-133.223h35.526c4.905 0 8.882-3.976 8.882-8.882-.001-21.483-15.258-39.396-35.528-43.512z" fill="url(#PIN_2_)"></path></g></g></g></svg></div>
            <h6 className='mt-2 mx-2 fw-bold'>Client Due Diligence</h6>
          </div>

          <StepperComponent />


        </div>
      </div>



      {/* kyc modal */}

      <Modal
        title={isEditing ? "Edit Screenig" : "Add Screening"}
        open={isModalOpen[0]}
        onOk={isEditing ? handleEdit : handleOk}
        onCancel={() => toggleModal(0, false)}
        classNames={classNames}
        styles={modalStyles}
      >
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Name</label>
          <Input
            name="name"
            value={formData.name}
            onChange={handleChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Position held</label>
          <Input
            name="positionHeld"
            value={formData.positionHeld}
            onChange={handleChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>S. No</label>
          <Input
            name="sNo"
            value={formData.sNo}
            onChange={handleChange}
            style={{ width: "70%", marginLeft: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Screening On</label>
          <DatePicker
            picker="month"
            value={formData.screeningOn}
            onChange={handleDateChange}
            style={{ width: "70%", maxHeight: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Sanctions List</label>
          <Checkbox
            name="sanctionsList"
            checked={formData.sanctionsList}
            onChange={handleCheckboxChange}
            style={{ justifySelf: "flex-start" }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>PEP</label>
          <Checkbox
            name="pep"
            checked={formData.pep}
            onChange={handleCheckboxChange}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Observations</label>
          <Input.TextArea
            name="observations"
            value={formData.observations}
            onChange={handleChange}
            style={{ width: "70%", maxHeight: 30 }}
          />
        </div>
        <div className='d-flex justify-content-between align-items-center mt-4'>
          <label className='mt-2'>Outputs</label>
          <Input.TextArea
            name="outputs"
            value={formData.outputs}
            onChange={handleChange}
            style={{ width: "70%", maxHeight: 30 }}
          />
        </div>
      </Modal>
    </>
  )
}

export default CddAndKyc