// currencies.js
const currenciesList = [
    { name: "United States Dollar", code: "USD" },
    { name: "Singapore Dollar", code: "SGD" },
    { name: "Euro", code: "EUR" },
    { name: "Indian Rupee", code: "INR" },
    { name: "Pound Sterling", code: "GBP" },
    { name: "Japanese Yen", code: "JPY" },
    { name: "Indonesian Rupiah", code: "IDR" },
    { name: "Brunei Dollar", code: "BND" },
    { name: "Chinese Yuan", code: "CNY" },
    { name: "Hong Kong Dollar", code: "HKD" },
    { name: "Mexican Peso", code: "MXN" },
    { name: "Australian Dollar", code: "AUD" },
    { name: "Argentinian Peso", code: "ARS" },
    { name: "Ugandan Shilling", code: "UGX" },
    { name: "Afghan Afghani", code: "AFN" },
    { name: "Albanian Lek", code: "ALL" },
    { name: "Algerian Dinar", code: "DZD" },
    { name: "Angolan Kwanza", code: "AOA" },
    { name: "East Caribbean Dollar", code: "XCD" },
    { name: "Armenian Dram", code: "AMD" },
    { name: "Aruban Florin", code: "AWG" },
    { name: "Saint Helena Pound", code: "SHP" },
    { name: "Azerbaijan Manat", code: "AZN" },
    { name: "Bahamian Dollar", code: "BSD" },
    { name: "Bahraini Dinar", code: "BHD" },
    { name: "Bangladeshi Taka", code: "BDT" },
    { name: "Barbadian Dollar", code: "BBD" },
    { name: "Belarusian Ruble", code: "BYN" },
    { name: "Belize Dollar", code: "BZD" },
    { name: "West African CFA Franc", code: "XOF" },
    { name: "Bermudian Dollar", code: "BMD" },
    { name: "Bhutanese Ngultrum", code: "BTN" },
    { name: "Bolivian Boliviano", code: "BOB" },
    { name: "Bosnia and Herzegovina Convertible Mark", code: "BAM" },
    { name: "Botswana Pula", code: "BWP" },
    { name: "Brazilian Real", code: "BRL" },
    { name: "Zambian Kwacha", code: "ZMW" },
    { name: "Bulgarian Lev", code: "BGN" },
    { name: "Burundi Franc", code: "BIF" },
    { name: "Cape Verdean Escudo", code: "CVE" },
    { name: "Central African CFA Franc", code: "XAF" },
    { name: "Cambodian Riel", code: "KHR" },
    { name: "Canadian Dollar", code: "CAD" },
    { name: "Cayman Islands Dollar", code: "KYD" },
    { name: "New Zealand Dollar", code: "NZD" },
    { name: "Chilean Peso", code: "CLP" },
    { name: "Colombian Peso", code: "COP" },
    { name: "Comorian Franc", code: "KMF" },
    { name: "Congolese Franc", code: "CDF" },
    { name: "Costa Rican Colon", code: "CRC" },
    { name: "Croatian Kuna", code: "HRK" },
    { name: "Cuban Peso", code: "CUP" },
    { name: "Netherlands Antillean Guilder", code: "ANG" },
    { name: "Czech Koruna", code: "CZK" },
    { name: "Danish Krone", code: "DKK" },
    { name: "Djiboutian Franc", code: "DJF" },
    { name: "Dominican Peso", code: "DOP" },
    { name: "Egyptian Pound", code: "EGP" },
    { name: "Eritrean Nakfa", code: "ERN" },
    { name: "Ethiopian Birr", code: "ETB" },
    { name: "Falkland Islands Pound", code: "FKP" },
    { name: "Fijian Dollar", code: "FJD" },
    { name: "CFP Franc", code: "XPF" },
    { name: "Gambian Dalasi", code: "GMD" },
    { name: "Georgian Lari", code: "GEL" },
    { name: "Ghanaian Cedi", code: "GHS" },
    { name: "Guatemalan Quetzal", code: "GTQ" },
    { name: "Guernsey Pound", code: "GGP" },
    { name: "Guinean Franc", code: "GNF" },
    { name: "Guyanese Dollar", code: "GYD" },
    { name: "Haitian Gourde", code: "HTG" },
    { name: "Honduran Lempira", code: "HNL" },
    { name: "Hungarian Forint", code: "HUF" },
    { name: "Icelandic Krona", code: "ISK" },
    { name: "SDR (Special Drawing Right)", code: "XDR" },
    { name: "Iranian Rial", code: "IRR" },
    { name: "Iraqi Dinar", code: "IQD" },
    { name: "Manx Pound", code: "IMP" },
    { name: "Israeli New Shekel", code: "ILS" },
    { name: "Jamaican Dollar", code: "JMD" },
    { name: "Jersey Pound", code: "JEP" },
    { name: "Jordanian Dinar", code: "JOD" },
    { name: "Kazakhstani Tenge", code: "KZT" },
    { name: "Kenyan Shilling", code: "KES" },
    { name: "Kuwaiti Dinar", code: "KWD" },
    { name: "Kyrgyzstani Som", code: "KGS" },
    { name: "Lao Kip", code: "LAK" },
    { name: "Lebanese Pound", code: "LBP" },
    { name: "Lesotho Loti", code: "LSL" },
    { name: "Liberian Dollar", code: "LRD" },
    { name: "Libyan Dinar", code: "LYD" },
    { name: "Swiss Franc", code: "CHF" },
    { name: "Macanese Pataca", code: "MOP" },
    { name: "Macedonian Denar", code: "MKD" },
    { name: "Malagasy Ariary", code: "MGA" },
    { name: "Malawian Kwacha", code: "MWK" },
    { name: "Malaysian Ringgit", code: "MYR" },
    { name: "Maldivian Rufiyaa", code: "MVR" },
    { name: "Mauritanian Ouguiya", code: "MRU" },
    { name: "Mauritian Rupee", code: "MUR" },
    { name: "Moldovan Leu", code: "MDL" },
    { name: "Mongolian Tugrik", code: "MNT" },
    { name: "Moroccan Dirham", code: "MAD" },
    { name: "Mozambican Metical", code: "MZN" },
    { name: "Myanmar Kyat", code: "MMK" },
    { name: "Namibian Dollar", code: "NAD" },
    { name: "Nepalese Rupee", code: "NPR" },
    { name: "Nicaraguan Cordoba", code: "NIO" },
    { name: "Nigerian Naira", code: "NGN" },
    { name: "North Korean Won", code: "KPW" },
    { name: "Norwegian Krone", code: "NOK" },
    { name: "Omani Rial", code: "OMR" },
    { name: "Pakistani Rupee", code: "PKR" },
    { name: "Papua New Guinean Kina", code: "PGK" },
    { name: "Paraguayan Guarani", code: " PYG" },
    { name: "Peruvian Sol", code: "PEN" },
    { name: "Philippine Peso", code: "PHP" },
    { name: "Polish Zloty", code: "PLN" },
    { name: "Qatari Riyal", code: "QAR" },
    { name: "Romanian Leu", code: "RON" },
    { name: "Russian Ruble", code: "RUB" },
    { name: "Rwandan Franc", code: "RWF" },
    { name: "Samoan Tala", code: "WST" },
    { name: "Sao Tome and Principe Dobra", code: "STN" },
    { name: "Saudi Arabian Riyal", code: "SAR" },
    { name: "Serbian Dinar", code: "RSD" },
    { name: "Seychellois Rupee", code: "SCR" },
    { name: "Sierra Leonean Leone", code: "SLL" },
    { name: "Solomon Islands Dollar", code: "SBD" },
    { name: "Somali Shilling", code: "SOS" },
    { name: "South African Rand", code: "ZAR" },
    { name: "South Korean Won", code: "KRW" },
    { name: "South Sudanese Pound", code: "SSP" },
    { name: "Sri Lankan Rupee", code: "LKR" },
    { name: "Sudanese Pound", code: "SDG" },
    { name: "Surinamese Dollar", code: "SRD" },
    { name: "Yemeni Rial", code: "YER" },
    { name: "Swazi Lilangeni", code: "SZL" },
    { name: "Swedish Krona", code: "SEK" },
    { name: "Syrian Pound", code: "SYP" },
    { name: "New Taiwan Dollar", code: "TWD" },
    { name: "Tajikistani Somoni", code: "TJS" },
    { name: "Tanzanian Shilling", code: "TZS" },
    { name: "Thai Baht", code: "THB" },
    { name: "Tongan Pa'anga", code: "TOP" },
    { name: "Trinidad and Tobago Dollar", code: "TTD" },
    { name: "Tunisian Dinar", code: "TND" },
    { name: "Turkish Lira", code: "TRY" },
    { name: "Turkmen Manat", code: "TMT" },
    { name: "Ukrainian Hryvnia", code: "UAH" },
    { name: "UAE Dirham", code: "AED" },
    { name: "Uruguayan Peso", code: "UYU" },
    { name: "Uzbekistani Som", code: "UZS" },
    { name: "Vanuatu Vatu", code: "VUV" },
    { name: "Venezuelan Bolivar", code: "VEF" },
    { name: "Vietnamese Dong", code: "VND" },
    { name: "Gibraltar Pound", code: "GIP" },
    { name: "Eastern Caribbean Dollar", code: "ECD" },
];

export default currenciesList;